import React from "react";

export default function FixedPair({
  width = 120,
  label,
  value,
  columns,
  padded,
  strong = false,
}) {
  return (
    <div
      className={[
        columns ? "row" : null,
      ]}
      style={
        padded
          ? { paddingBottom: 5, marginBottom: 5 }
          : {}
      }
    >
      <div
        className={[columns ? "column" : null]}
        style={{ width, display: "inline-block", verticalAlign: "top" }}
      >
        { strong ? (<strong>{label}</strong>) : label }
      </div>
      <div
        className={[columns ? "column" : null]}
        style={{ width: `calc(100% - ${width})`, display: "inline-block" }}
      >
        { value }
      </div>
    </div>
  );
}
