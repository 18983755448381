import React, { useState, useEffect, useCallback } from "react";
import Fuse from "fuse.js";
import { useApi } from "@providers/api";
import { useNavigate } from "react-router-dom";
import "./style.css"
import BreadCrumb from "@shared/BreadCrumb";

const searchOptions = {
  keys: [
    "name",
    "address",
    "phone",
  ],
};

export default function Offices() {
  const navigate = useNavigate();
  const api = useApi();
  const [offices, setOffices] = useState([]);
  const [filteredOffices, setFilteredOffices] = useState([]);
  const [filteredBySearch, setFilteredBySearch] = useState(null);
  const [fuse, setFuse] = useState(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");

  useEffect(() => {
    api.get("/offices").then((response) => {
      setOffices(response);
      setFilteredOffices(response);
    });
  }, []);

  useEffect(() => {
    if (offices) {
      setFuse(new Fuse(offices, searchOptions));
    }
  }, [offices]);

  // Combine filters and search
  useEffect(() => {
    if (filteredBySearch) {
      setFilteredOffices(filteredBySearch);
    } else {
      setFilteredOffices(offices);
    }
  }, [filteredBySearch]);

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    if (!search) {
      setFilteredBySearch(null);
      return;
    }

    const filtered = fuse.search(search);
    setFilteredBySearch(filtered.map((result) => result.item));
  };


  return (
    <div className="container">
      <BreadCrumb items={[["Admin", "/admin"], "Offices"]} />
      <div className="row">
        <div className="column column-75">
          <input
            type="text"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
          />
        </div>
        <div className="column column-25 text-right">
          <button onClick={() => navigate("/admin/offices/create") }>Create A New Office</button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Extension</th>
          </tr>
        </thead>
        <tbody>
          {filteredOffices?.map((office) => (
            <tr key={office.id} className="orders--action-row" onClick={() => navigate(`/admin/offices/${office.id}`)}>
              <td>{office.name}</td>
              <td>{office.address}</td>
              <td>{office.phone}</td>
              <td>{office.ext}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
