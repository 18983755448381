import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useUser } from "@providers/user";
import { useApi } from "@providers/api";
import submitOrderValidation from "../validations/submitOrder";
import { useNavigate } from "react-router-dom";
import Loading from "@shared/Loading";

const OrderContext = createContext({});

export const useOrder = () => useContext(OrderContext);

const OrderProvider = ({ children }) => {
  const user = useUser();
  const api = useApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [purchase_orders, setPurchaseOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [office, setOffice] = useState(null);
  const [skipComms, setSkipComms] = useState(false);

  useEffect(() => {
    if (user) {
      setOffice(null);
      setProducts([]);
      setPurchaseOrders([]);
    }
  }, []);

  useEffect(() => {
    // If there is no office, there is no need to fetch products
    if (!office) {
      setProducts([]);
      setPurchaseOrders([]);
      return;
    }

    // Fetch products for the selected office
    fetchProducts(office.id);

    if (!purchase_orders.length) createPurchaseOrder();
  }, [office]);

  useEffect(() => {
    setPurchaseOrders(
      purchase_orders.map((po) => ({
        ...po,
        oid: `${new Date().getTime()}-${
          purchase_orders?.length
        }-${Math.random()}`,
        products: [],
      }))
    );
  }, [products]);

  const initializeBlankPurchaseOrder = function () {
    return {
      oid: `${new Date().getTime()}-${
        purchase_orders?.length
      }-${Math.random()}`,
      purchase_order_number: "",
      doctor: null,
      products: [],
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      contact_phone_extension: "",
      date_of_service: "",
      shipping_address: "",
    };
  };

  const removePurchaseOrder = (purchase_order) => {
    setPurchaseOrders(purchase_orders.filter((po) => po !== purchase_order));
  };

  const updatePurchaseOrder = (index, fields) => {
    const po = purchase_orders[index];
    setPurchaseOrders((prev) => {
      const newPurchaseOrders = [...prev];
      newPurchaseOrders[index] = { ...po, ...fields };
      return newPurchaseOrders;
    });
  };

  const addProduct = (index, product) => {
    const products = purchase_orders[index]?.products?.concat([product]);
    updatePurchaseOrder(index, { products });
  };

  const createPurchaseOrder = function () {
    setPurchaseOrders([...purchase_orders, initializeBlankPurchaseOrder()]);
  };

  const fetchProducts = async (office_id) => {
    try {
      const response = await api.get("/products");
      setProducts(response);
    } catch (error) {
      alert("We can't fetch products right now, please try back later.");
      console.error("Error fetching products", error);
    }
  };

  const submitOrder = useCallback(
    (e) => {
      e.preventDefault();

      // Submit the order to the API
      const order = {
        office_id: office.id,
        skip_comms: skipComms || null,
        purchase_orders: purchase_orders.map((po) => ({
          ...po,
          product_sizes: po.products.map((product) => product.product_size_id),
        })),
      };

      const errors = submitOrderValidation(order);
      if (Object.keys(errors).length) {
        alert("Please correct the following errors: " + JSON.stringify(errors));
        return;
      }
      setLoading(true);
      api.post("/orders", { order }).then((response) => {
        setLoading(false);
        if (!response || response.error) {
          alert("We can't submit your order right now, please try back later.");
          console.error(response);
          return;
        }

        navigate("/orders/confirmation/" + response.id);
      });
    },
    [office, purchase_orders]
  );

  return (
    <OrderContext.Provider
      value={{
        purchase_orders,
        products,
        office,
        setOffice,
        createPurchaseOrder,
        updatePurchaseOrder,
        removePurchaseOrder,
        addProduct,
        submitOrder,
        setSkipComms,
        skipComms,
      }}
    >
      {loading ? <Loading /> : children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
