import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useApi } from "@providers/api";
import BreadCrumb from "@shared/BreadCrumb";
import { chunkArray } from "@util";

export default function Office() {
  const api = useApi();
  const [account, setAccount] = useState(null);
  const { id } = useParams();

  const load = () => {
    api.get(`/accounts/${id}`).then((account) => {
      setAccount(account);
    });
  };

  useEffect(() => {
    load();
  }, [id]);

  if (!account) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    api.put(`/accounts/${id}`, { account: data }).then((resp) => {
      if (resp.errors || resp.ok === false) {
        alert(
          "We're sorry, but we couldn't update the account. Please try again."
        );
        return;
      }
      load();
      alert("Account updated successfully!", "green");
    });
  };

  return (
    <div className="container">
      <BreadCrumb
        items={[
          ["Admin", "/admin"],
          ["Accounts", "/admin/accounts"],
          account.name,
        ]}
      />
      {account.offices.length === 0 ? (
        <div style={{ color: "red", padding: "30px 0" }}>
          <strong>Warning:</strong> This account has no offices assigned to it.
        </div>
      ) : null}

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              defaultValue={account.name}
              required
            />
          </div>
          <div className="column">
            <label>Status</label>
            <select name="enabled" id="enabled" defaultValue={account.enabled}>
              <option value="true">Enabled</option>
              <option value="false">Disabled</option>
            </select>
          </div>
        </div>
        <button type="submit" style={{}}>
          Update
        </button>
      </form>
    </div>
  );
}
