import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "@providers/api";
import ShowOrderDetails from "@shared/OrderDetails/show"
import ShowPurchaseOrders from "@shared/PurchaseOrder/list";
import { NotFound } from "@pages/error";
import Loading from "@shared/Loading";

export default function OrderConfirmation() {
  const [order, setOrder] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const api = useApi();
  const { id } = useParams();

  useEffect(() => {
    api.get(`/orders/${id}`).then((order) => {
      if (order?.status === 404) {
        setNotFound(true);
        return;
      }

      setOrder(order);
    });
  }, [id]);

  if (notFound) {
    return <NotFound message={(
      <>
        We were unable to locate this order. It may have been deleted, or replaced by a different order.
        <br/>
        <a href="/orders">Follow this link to search for your order</a>.
      </>
    )} />
  }

  if (!order) return <Loading />;

  return (
    <div className="container padded">
      <ShowOrderDetails order={order} print />
      <hr />
      <ShowPurchaseOrders order={order} />
    </div>
  );
}
