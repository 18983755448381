import React from "react";
import ShowPurchaseOrder from "./private/show";

import "./style.css";

export default function PurchseOrderList({ order }) {

  if (!order?.purchase_orders?.length) {
    return null;
  }

  return order.purchase_orders.map((purchase_order, index) => (
    <div key={purchase_order?.id}>
      <ShowPurchaseOrder
        purchase_order={purchase_order}
      />
      { index < order?.purchase_orders?.length - 1 && (<><br /><br/></>)}
    </div>
  ))
}
