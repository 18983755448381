import React from "react";
import { formatPrice } from "@util";
export default function OrderProducts({
  index,
  purchase_order,
  removeProduct,
}) {
  if (!purchase_order?.products.length) return null;

  return (
    <div>
      <table className="compact">
        <thead>
          <tr>
            <th>Price Per Unit</th>
            <th>Product</th>
            <th>Size</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {purchase_order.products.map((product) => (
            <tr key={product.oid}>
              <td>{formatPrice(product.price)}</td>
              <td>{product.name}</td>
              <td>{product.size}</td>
              <td className="text-right">
                <a onClick={() => removeProduct(product)}>Remove</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
