import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "@providers/api";
import EditPurchaseOrder from "@shared/PurchaseOrder/edit";
import OrderDetails from "@shared/OrderDetails/show";
import BreadCrumb from "../../../shared/BreadCrumb";

export default function Order() {
  const api = useApi();
  const [order, setOrder] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    api.get(`/orders/${id}`).then((order) => {
      setOrder(order);
    });
  }, [id]);

  return (
    <div className="container">
      <BreadCrumb items={[["Admin", "/admin"], ["Orders", "/admin/orders"], order?.order_number]} />
      <OrderDetails order={order} />
      <hr/>
      {
        order?.purchase_orders?.map((purchase_order, index) => (
          <div key={purchase_order?.id}>
            <EditPurchaseOrder
              purchase_order={purchase_order}
              order={order}
              reload={() => api.get(`/orders/${id}`).then(setOrder)}
            />
            { index < order?.purchase_orders?.length - 1 && (<><br /><br/></>)}
          </div>
        ))
      }
    </div>
  );
}
