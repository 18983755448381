import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "@providers/api";
import BreadCrumb from "@shared/BreadCrumb";

export default function CreateAccount() {
  const api = useApi();
  const navigate = useNavigate();
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    api.post(`/accounts`, { account: data }).then((account) => {
      navigate(`/admin/accounts/${account.id}`);
    });
  };

  return (
    <div className="container">
      <BreadCrumb
        items={[
          ["Admin", "/admin"],
          ["Accounts", "/admin/accounts"],
          "Create Account",
        ]}
      />
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name</label>
          <input required type="text" name="name" id="name" />
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
}
