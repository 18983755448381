import React, {useState, useEffect} from "react";
import { useOrder } from "../providers";

export default function OrderContactFields({purchase_order, handleAttributeUpdate}) {
  const { updatePurchaseOrder } = useOrder();

  return (
    <div className="row">
      <div className="column">
        <label>
          Contact Name
          <input
            type="text"
            value={purchase_order.contact_name}
            required
            onChange={(e) => handleAttributeUpdate('contact_name', e.target.value)}
          />
        </label>
      </div>
      <div className="column">
        <label>
          Contact Email
          <input
            type="email"
            placeholder="Email"
            required
            value={purchase_order.contact_email}
            onChange={e => handleAttributeUpdate('contact_email', e.target.value)}
          />
        </label>
      </div>

      <div className="column">
        <label>
          Contact Phone
          <input
            type="text"
            required
            value={purchase_order.contact_phone}
            onChange={e => handleAttributeUpdate('contact_phone', e.target.value)}
          />
        </label>
      </div>
      <div className="column">
        <label>
          Contact Extension
          <input
            type="text"
            defaultValue={purchase_order.contact_phone_extension}
            onChange={e => handleAttributeUpdate('contact_phone_extension', e.target.value)}
          />
        </label>
      </div>
    </div>
  );
}
