import React, { createContext, useState, useEffect } from "react";
import apiUrl from "@env/api";

const ApiContext = createContext("");
export const useApi = () => React.useContext(ApiContext);

const ApiProvider = ({ children }) => {
  const inspectResponse = async (response) => {
    if (!response) return;
    if (response.status === 403 || response.status === 401) {
      window.location.href = "/?_=" + new Date().getTime();
      return { status: response.status, ok: false };
    }

    // if the response is not ok, return the status code
    if (!response.ok) {
      try {
        const json = await response.json();
        return { ...json, status: response.status, ok: response.ok };
      } catch {
        return { status: response.status, ok: false };
      }
    }

    // If there is no content, return an empty object
    if (response.status === 204) {
      return { status: 204, ok: response.ok };
    }

    const json = await response.json();

    if (!response.ok) {
      return { ...json, status: response.status, ok: response.ok };
    }

    return json;
  };

  const api = {
    blob: async (url) => {
      const token = window.localStorage.getItem("token");

      const response = await fetch(`${apiUrl}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    },
    get: async (url, queryParams = {}) => {
      const token = window.localStorage.getItem("token");
      const queryString = Object.keys(queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        )
        .join("&");
      const fullUrl = `${apiUrl}${url}${
        Object.keys(queryParams).length ? (url.includes("?") ? "&" : "?") : ""
      }${queryString}`;

      const response = await fetch(fullUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let json = await inspectResponse(response);
      return json;
    },
    post: async (url, data) => {
      const token = window.localStorage.getItem("token");
      const response = await fetch(`${apiUrl}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      let json = await inspectResponse(response);
      return json;
    },
    put: async (url, data) => {
      const token = window.localStorage.getItem("token");
      const response = await fetch(`${apiUrl}${url}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      let json = await inspectResponse(response);
      return json;
    },
    delete: async (url) => {
      const token = window.localStorage.getItem("token");
      const response = await fetch(`${apiUrl}${url}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let json = await inspectResponse(response);
      return json;
    },
  };

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export default ApiProvider;
