import React, { useState, useEffect } from "react";
import { formatPrice, sumPurchaseOrder } from "@util";
import PurchaseOrderDetails from "./details";
import { useApi } from "@providers/api";

export default function ShowPurchaseOrder({ purchase_order }) {
  const api = useApi();
  const [refs, setRefs] = useState({});

  const updateSerialNumber = async (purchase_order_product_id) => {
    const serial_number = refs[purchase_order_product_id].current.value;
    const confirmed = window.confirm(
      `Are you sure you want to update this serial number to ${serial_number}?\n\nThis action will require reconciliation and we may contact you to revise invoicing.`
    );
    if (!confirmed) return;

    const res = await api.put(
      `/purchase_order_products/${purchase_order_product_id}`,
      { serial_number }
    );
    if (res.error) {
      alert("There was an error updating the serial number. Please try again.");
      return;
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    setRefs(
      purchase_order?.purchase_order_products?.reduce(
        (acc, purchase_order_product) => {
          acc[purchase_order_product?.id] = React.createRef();
          return acc;
        },
        {}
      )
    );
  }, [purchase_order]);

  return (
    <div className="show-purchase-order">
      <PurchaseOrderDetails purchase_order={purchase_order} />

      <table className="show-purchase-order--table text-left">
        <thead>
          <tr>
            <th>Product</th>
            <th>Size</th>
            <th>Serial Number</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {purchase_order?.purchase_order_products?.map(
            (purchase_order_product) => (
              <tr key={purchase_order_product?.id}>
                <td>{purchase_order_product?.name}</td>
                <td>{purchase_order_product?.size}</td>
                <td>
                  {purchase_order_product?.serial_number ? (
                    <div className="row">
                      <input
                        ref={refs[purchase_order_product?.id]}
                        type="text"
                        defaultValue={purchase_order_product?.serial_number}
                        className="column"
                        style={{ marginRight: "20px" }}
                      />
                      <button
                        className="column button button-outline"
                        style={{ maxWidth: "100px" }}
                        onClick={() =>
                          updateSerialNumber(purchase_order_product?.id)
                        }
                      >
                        Update
                      </button>
                    </div>
                  ) : (
                    <span>No Serial Number</span>
                  )}
                </td>
                <td>{formatPrice(purchase_order_product?.price)}</td>
              </tr>
            )
          )}
          <tr className="show-purchase-order--totals">
            <td colSpan="2">Total</td>
            <td>{sumPurchaseOrder(purchase_order)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
