import React, { useState } from "react";
import { useUser } from "@providers/user";
import API_URL from "@env/api";

const ResetPassword = () => {
  const token = window.location.search.split("=")[1];
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const { setUser, setToken, setTokenValid, setTokenValidatedAt} = useUser();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset error message
    setError("");

    if (password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }

    fetch(`${API_URL}/change-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password, password_confirmation, token }),
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.ok) {
          return data;
        } else {
          setError(data.error || "Invalid Credentials");
        }
      })
      .then((data) => {
        if (!data) return;

        window.localStorage.setItem("token", data.token);
        setUser(data.user);
        setToken(data.token);
        setTokenValidatedAt(Date.now());
        setTokenValid(true);
        window.location = "/";
      })
      .catch((error) => {
        console.error("Error:", error.message)
        setError(error.message);
      });
  };

  return (
    <>
      <div
        className="center-middle"
        style={{ height: "100vh" }}
      >
        <div>
          <img width="300px" height="88.33px" style={{margin: '0 auto 30px', display: 'block'}} src="/EWCC-HS.png" alt="EWCC Logo" />
          {error && <div className="callout alert">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="column text-left">
                <label>
                  Password
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </label>
              </div>
              <div className="column text-left">
                <label>
                  Password
                  <input
                    type="password"
                    placeholder="Password Confirmation"
                    value={password_confirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="column text-left">
              <button type="submit" className="button expanded">
                Reset Password
              </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
