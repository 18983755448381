import React from "react";

export default function MailTo({ email, subject, body, ...props }) {
  return (
    <a
      {...props}
      href={`mailto:${email}?subject=${encodeURIComponent(
        subject || ''
      )}&body=${encodeURIComponent(body||'')}`}
    >
      {email}
    </a>
  );
}