import React from 'react';
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ErrorFallback = ({ error }) => {  
  return (
    <div className="container">
      <div className="center-middle" style={{ minHeight: '100vh' }}>

          <div>
            <h1>Something went wrong.</h1>
            <p>We're sorry, but something went wrong. Please try logging in again, or contact support if the problem persists.</p>
            <div style={{fontFamily: 'monospace'}}>{error.message}</div>
            <br/>
            <button className="button radius" onClick={() => { window.localStorage.clear(); window.location.reload()}}>Logout</button>
          </div>
      </div>
    </div>
  )
}

export const ErrorPage = ({message, linkBack}) => {
  let query = useQuery();
  message = message || query.get("message") || "";
  linkBack = linkBack || query.get("link-back");

  return (
    <div className="container">
      <div className="center-middle" style={{ minHeight: '100vh' }}>

          <div>
            <h1>Something went wrong.</h1>
            <p>We're sorry, but something went wrong. Contact support if the problem persists.</p>
            <p>{message}</p>
            {linkBack && <a href={linkBack} className="button radius">Try Again</a>}
          </div>
      </div>
    </div>
  )
}

export const NotFound = ({ message }) => {  
  let query = useQuery();
  message = message || query.get("message");

  return (
    <div className="container">
      <div className="center-middle" style={{ minHeight: '100vh' }}>

          <div>
            <h1>Not Found.</h1>
            <p>{message}</p>
          </div>
      </div>
    </div>
  )
}

export default function ErrorBoundaryComponent({ children }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  )
}
