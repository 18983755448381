import React, { useState, useEffect } from "react";
import { useApi } from "@providers/api";
import {
  formatDate,
  formatDateTime,
  formatPrice,
  formatPhoneNumber,
} from "@util";
import { useParams } from "react-router-dom";
import BreadCrumb from "@shared/BreadCrumb";
import Loading from "@shared/Loading";
import FixedPair from "@shared/FixedPair";
import MailTo from "@shared/MailTo";

export default function ShowInvoice() {
  const api = useApi();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pdf, setPdf] = useState(null);
  const [generatingPdf, setGeneratingPdf] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    api.get(`/invoices/${id}`).then((response) => {
      if (response.status === 404) return (window.location.href = "/404");
      if (response.error) return console.error(response.error);

      setInvoice(response);
      setLoading(false);
    });
    window.scrollTo(0, 0);
  }, []);

  const generatePDF = () => {
    setGeneratingPdf(true);
    api.blob(`/invoices/${id}/pdf`).then(async (response) => {
      const arrayBuffer = await response.arrayBuffer();
      const blob = new Blob([arrayBuffer], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setGeneratingPdf(false);
      setPdf(url);
    });
  };

  if (loading) return <Loading />;

  return (
    <div className="container pdf-content no-page-break">
      <BreadCrumb
        items={[["Invoices", "/invoices"], invoice?.invoice_number]}
      />

      <div className="row">
        <div className="column">
          <h3>Invoice No. {invoice.invoice_number}</h3>
        </div>
        <div className="column text-right">
          {(pdf && (
            <a className="button" href={pdf} download="invoice.pdf">
              Download PDF
            </a>
          )) ||
            (generatingPdf && <span>generating...</span>) || (
              <a className="button" onClick={generatePDF}>
                Generate PDF
              </a>
            )}
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="column">
          <FixedPair
            strong
            padded
            columns
            label="Created"
            value={formatDateTime(invoice?.created_at)}
          />
          <FixedPair
            padded
            columns
            label="Total"
            value={formatPrice(invoice?.total)}
          />
          <FixedPair
            noPrint
            padded
            columns
            label="Paid At"
            value={
              invoice?.paid_at ? formatDateTime(invoice?.paid_at) : "Unpaid"
            }
          />
          <FixedPair
            padded
            columns
            label="Account"
            value={invoice?.account?.name}
          />
        </div>

        <div className="column">
          <FixedPair
            strong
            padded
            columns
            label="Updated"
            value={
              invoice?.created_at === invoice?.updated_at
                ? "N/A"
                : formatDateTime(invoice?.updated_at)
            }
          />

          <FixedPair padded columns label="Status" value={invoice?.status} />

          <FixedPair
            padded
            columns
            label="Sent At"
            value={
              invoice?.sent_at ? formatDateTime(invoice?.sent_at) : "Unsent"
            }
          />
        </div>
      </div>

      <hr />

      <h4>Purchase Orders</h4>

      {invoice?.invoice_purchase_orders?.map((invoice_purchase_order) => (
        <div
          style={{
            padding: 15,
            border: "1px solid #ccc",
            marginBottom: 30,
            boxShadow: "2px 2px 1px -1px rgba(0,0,0,0.2)",
          }}
        >
          <div key={invoice_purchase_order?.id} className="row">
            <div className="column">
              <FixedPair
                width={150}
                padded
                columns
                strong
                label="PO No."
                value={
                  invoice_purchase_order?.purchase_order?.purchase_order_number
                }
              />
              <FixedPair
                width={150}
                padded
                columns
                label="Office"
                value={
                  invoice_purchase_order?.purchase_order?.order?.office?.name
                }
              />
              <FixedPair
                width={150}
                padded
                columns
                label="Date Ordered"
                value={formatDate(
                  invoice_purchase_order?.purchase_order?.created_at
                )}
              />
            </div>
            <div className="column">
              <FixedPair
                width={150}
                padded
                columns
                label="Order No."
                value={invoice_purchase_order?.purchase_order?.order_number}
              />

              <FixedPair
                width={150}
                padded
                columns
                label="Ordered By"
                value={
                  <>
                    <div>
                      {
                        invoice_purchase_order?.purchase_order?.order
                          ?.ordering_user?.name
                      }
                    </div>
                    <div>
                      {formatPhoneNumber(
                        invoice_purchase_order?.purchase_order?.order
                          ?.ordering_user?.phone,
                        invoice_purchase_order?.purchase_order?.order
                          ?.ordering_user?.ext
                      )}
                    </div>
                    <div>
                      <MailTo
                        email={
                          invoice_purchase_order?.purchase_order?.order
                            ?.ordering_user?.email
                        }
                      />
                    </div>
                  </>
                }
              />
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Serial Number</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {invoice_purchase_order?.purchase_order?.purchase_order_products?.map(
                (pop) => (
                  <tr key={pop.id}>
                    <td>{pop.name}</td>
                    <td>{pop.serial_number}</td>
                    <td>{formatPrice(pop.price)}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}
