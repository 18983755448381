import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from "@pages/login";
import ResetPassword from "@pages/login/reset-password";
import Order from "@pages/order";
import Orders from "@pages/orders";
// import PurchaseOrders from "@pages/purchase_orders";
import OrderConfirmation from "@pages/orders/confirmation";
import ShowOrder from "@pages/order/show";
import Admin from "@pages/admin";
import AdminUsers from "@pages/admin/users";
import AdminUser from "@pages/admin/users/user";
import AdminOffices from "@pages/admin/offices";
import AdminOffice from "@pages/admin/offices/office";
import AdminCreateOffice from "@pages/admin/offices/create";
import AdminProducts from "@pages/admin/products";
import AdminProduct from "@pages/admin/products/product";
import AdminCreateProduct from "@pages/admin/products/create";
import AdminVendors from "@pages/admin/vendors";
import AdminVendor from "@pages/admin/vendors/show";
import AdminCreateVendor from "@pages/admin/vendors/create";
import AdminOrders from "@pages/admin/orders";
import AdminOrder from "@pages/admin/orders/order";
import AdminCreateUser from "@pages/admin/users/create";
import AdminCreateInvoice from "@pages/admin/invoices/create"
import AdminInvoices from "@pages/admin/invoices";
import AdminShowInvoice from "@pages/admin/invoices/show";
import AdminCreateAccount from "@pages/admin/accounts/create";
import AdminAccounts from "@pages/admin/accounts/index";
import AdminAccount from "@pages/admin/accounts/account";
import AccountingInvoices from "@pages/accounting/invoices";
import AccountingInvoice from "@pages/accounting/invoices/show";
import Nav from "./components/Nav";
import Home from "@pages/home";
import Loading from "@shared/Loading";
import { useUser } from "@providers/user";
import { NotFound, ErrorPage } from "@pages/error";
import Help from "@pages/admin/help";

const Navigation = () => {
  const user = useUser();
  const [superUser, setSuperUser] = useState(false);
  const [accountingUser, setAccountingUser] = useState(false);
  
  useEffect(() => {
    if (user?.user?.roles?.includes("super")) {
      setSuperUser(true);
    }
    if (user?.user?.roles?.includes("accounting")) {
      setAccountingUser(true);
    }
  }, [user]);

  if (user.loading) {
    return <Loading />;
  }

  if (!user.tokenValid) {
    return (
      <Router>
        <Routes>
          <Route path="*" element={ <Login /> } />
          <Route path="/reset-password" element={ <ResetPassword /> } />
        </Routes>
      </Router>
    )
  }

  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/error" element={ <ErrorPage /> } />
        <Route path="/order" element={ <Order /> } />
        <Route path="/orders" element={ <Orders />} />
        <Route path="/orders/confirmation/:id" element={ <OrderConfirmation />} />
        <Route path="/orders/:id" element={ <ShowOrder />} />
        <Route path="/404" element={ <NotFound />} />
        { superUser ? <Route path="/admin" element={ <Admin />} /> : null }
        { superUser ? <Route path="/admin/users" element={ <AdminUsers />} /> : null }
        { superUser ? <Route path="/admin/users/create" element={ <AdminCreateUser />} /> : null }
        { superUser ? <Route path="/admin/users/:id" element={ <AdminUser />} /> : null }
        { superUser ? <Route path="/admin/offices" element={ <AdminOffices />} /> : null }
        { superUser ? <Route path="/admin/offices/:id" element={ <AdminOffice />} /> : null }
        { superUser ? <Route path="/admin/offices/create" element={ <AdminCreateOffice />} /> : null }
        { superUser ? <Route path="/admin/products" element={ <AdminProducts />} /> : null }
        { superUser ? <Route path="/admin/products/:id" element={ <AdminProduct />} /> : null }
        { superUser ? <Route path="/admin/products/create" element={ <AdminCreateProduct />} /> : null }
        { superUser ? <Route path="/admin/orders" element={ <AdminOrders />} /> : null }
        { superUser ? <Route path="/admin/orders/:id" element={ <AdminOrder />} /> : null }
        { superUser ? <Route path="/admin/invoices/create" element={ <AdminCreateInvoice />} /> : null }
        { superUser ? <Route path="/admin/invoices" element={ <AdminInvoices />} /> : null }
        { superUser ? <Route path="/admin/invoices/:id" element={ <AdminShowInvoice />} /> : null }
        { superUser ? <Route path="/admin/accounts" element={ <AdminAccounts />} /> : null }
        { superUser ? <Route path="/admin/accounts/create" element={ <AdminCreateAccount />} /> : null }
        { superUser ? <Route path="/admin/accounts/:id" element={ <AdminAccount />} /> : null }
        { superUser ? <Route path="/admin/vendors" element={ <AdminVendors />} /> : null }
        { superUser ? <Route path="/admin/vendors/create" element={ <AdminCreateVendor />} /> : null }
        { superUser ? <Route path="/admin/vendors/:id" element={ <AdminVendor />} /> : null }
        { superUser ? <Route path="/help" element={ <Help />} /> : null }
        { accountingUser || superUser ? <Route path="/invoices" element={ <AccountingInvoices />} /> : null }
        { accountingUser || superUser ? <Route path="/invoices/:id" element={ <AccountingInvoice />} /> : null }
      </Routes>
    </Router>
  );
}

export default Navigation;