import React, { createContext, useState, useEffect } from "react";
import { fetchOffices, validateToken } from "./api";
import { useError } from "@providers/error";

const UserContext = createContext();
export const useUser = () => React.useContext(UserContext);
const UserProvider = ({ children }) => {
  const { handleError } = useError();
  const sessionToken = window.localStorage.getItem('token');
  const sessionUser = window.localStorage.getItem("user");
  const [user, setUser] = useState(sessionUser && JSON.parse(sessionUser));
  const [token, setToken] = useState(sessionToken);
  const [offices, setOffices] = useState([]);
  const [tokenValidatedAt, setTokenValidatedAt] = useState(null);
  const [tokenValid, setTokenValid] = useState(false);
  const [loading, setLoading] = useState(true);

  const instantiateUser = async () => {
    // Go stright to login page if there is no token or no user
    if (!token || !user) {
      setLoading(false);
      return;
    }

    // Validate the token
    const valid = await validateToken(token);
    if (!valid) {
      setLoading(false);
      return;
    }
    setTokenValid(true);
    setTokenValidatedAt(Date.now());

    // Fetch the user's offices
    const offices = await fetchOffices(token);
    if (offices.error) {
      handleError(new Error(offices.error));
      return;
    }

    if (!offices || !offices.length) {
      handleError(
        new Error(
          "There seems to be an issue associating your account with an office. Please contact support."
        )
      );
      return;
    }

    setOffices(offices);
    setLoading(false);
  };

  useEffect(() => {
    instantiateUser();
  }, [token, user]);

  // // Persist the token, user, and offices to localStorage
  useEffect(() => {
    if (token) {
      window.localStorage.setItem("token", token);
    } else {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("offices");
    }

    if (user) {
      window.localStorage.setItem("user", JSON.stringify(user));
    } else {
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("offices");
    }
  }, [token, user]);

  return (
    <UserContext.Provider
      value={{ loading, offices, user, token, setToken, setUser, tokenValid, setTokenValidatedAt, setTokenValid }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
