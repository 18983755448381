import React, { useState, useEffect, useCallback } from "react";
import Fuse from "fuse.js";
import { useUser } from "@providers/user";
import { useApi } from "@providers/api";
import { formatDate } from "@util";
import "./style.css";
import BreadCrumb from "../../../shared/BreadCrumb";

const searchOptions = {
  keys: ["name", "email", "user_roles.role", "account.name"],
};

export default function Users() {
  const api = useApi();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredBySearch, setFilteredBySearch] = useState(null);
  const [fuse, setFuse] = useState(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");

  useEffect(() => {
    api.get("/users").then((response) => {
      setUsers(response);
      setFilteredUsers(response);
    });
  }, []);

  useEffect(() => {
    if (users) {
      setFuse(new Fuse(users, searchOptions));
    }
  }, [users]);

  // Combine filters and search
  useEffect(() => {
    if (filteredBySearch) {
      setFilteredUsers(filteredBySearch);
    } else {
      setFilteredUsers(users);
    }
  }, [filteredBySearch]);

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    if (!search) {
      setFilteredBySearch(null);
      return;
    }

    const filtered = fuse.search(search);
    setFilteredBySearch(filtered.map((result) => result.item));
  };

  return (
    <div className="container">
      <BreadCrumb items={[["Admin", "/admin"], "Users"]} />
      <div className="row">
        <div className="column column-75">
          <input
            type="text"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
          />
        </div>
        <div className="column column-25 text-right">
          {/* Create a new user CTA */}
          <button onClick={() => (window.location = "/admin/users/create")}>
            Create A New User
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Account</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers?.map((user) => (
            <tr
              key={user.id}
              className="orders--action-row"
              onClick={() => (window.location = `/admin/users/${user.id}`)}
            >
              <td>{user.name}</td>
              <td>
                {user.user_roles[0].role === "super"
                  ? "🦸‍♂️"
                  : user.account?.name}
              </td>
              <td>{user.email}</td>
              <td style={{ textTransform: "capitalize" }}>
                {user.user_roles[0].role}
              </td>
              <td>{user.active ? "Active" : "Disabled"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
