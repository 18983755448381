import React from "react";
import { formatPrice } from '@util'
export default function Totals({ purchase_order }) {
  if (!purchase_order?.products?.length) return null;

  return (
    <div className="panel" style={{marginTop: 50, marginBottom: 50}}>
      Total Cost: {formatPrice(purchase_order.products.reduce((acc, product) => acc + product.price, 0))}
    </div>
  );
}