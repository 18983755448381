export function formatPhoneNumber(phoneNumber = "") {

  if (!phoneNumber) {
      return null;
  }

  // Check if the phone number starts with + and has at least 11 digits including the country code
  if (phoneNumber.startsWith("+") && phoneNumber.length >= 12) {
      // Remove the leading +
      phoneNumber = phoneNumber.substring(1);
  }

  // Validate phone number length after removing +
  if (phoneNumber.length !== 11) {
      return phoneNumber;
  }

  // Extract the country code (first digit)
  const countryCode = phoneNumber.substring(0, 1);
  // Extract the main phone number parts
  const areaCode = phoneNumber.substring(1, 4);
  const centralOfficeCode = phoneNumber.substring(4, 7);
  const lineNumber = phoneNumber.substring(7);

  // Format and return the phone number
  return `+1 (${areaCode}) ${centralOfficeCode}-${lineNumber}`;
}