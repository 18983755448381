const dateTimeOptions = { 
  year: 'numeric', 
  month: '2-digit', 
  day: '2-digit', 
  hour: '2-digit', 
  minute: '2-digit', 
  hour12: true 
};

export function formatDateTime(dateString) {
  if (!dateString) {
    return "";
  }
  
  try {
    return new Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(dateString));
  } catch (e) {
    return "";
  }
}

export function formatDate(dateString) {
  if (!dateString) {
    return "";
  }

  try {
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(dateString));
  } catch (e) {
    return "";
  }
}