import React from "react";
import { useOrder } from "../providers";

export default function SelectOrderContact({ onSelect }) {
  const {
    office,
  } = useOrder();
  if (!office) return null;

  const handleSelect = (e) => {
    const id = e.target.value;
    if (id === "new") {
      onSelect("new");
    } else {
      onSelect(office.office_users?.find((office_user) => office_user.id == id)?.user);
    }
  };

  return (
    <div className="column">
      <label>
        Order Contact
        <select onChange={handleSelect}>
          <option value="">Select a contact</option>
          {office.office_users?.map((office_user) => (
            <option key={office_user?.id} value={office_user?.id}>
              {office_user?.user?.name}
            </option>
          ))}
          <option value="new">New Contact</option>
        </select>
      </label>
    </div>
  );
}
