import React from 'react';
import { useOrder } from '../providers';
import PurchaseOrder from './PurchaseOrder';


export default function PurchaseOrders() {
  const { purchase_orders } = useOrder();

  return purchase_orders.map((purchase_order, index) => (
        <div key={purchase_order.oid} className="row" style={{padding: "0 10px", marginTop: "2rem", marginBottom: "2rem"}}>
          <PurchaseOrder purchase_order={purchase_order} index={index} />
        </div>
      )
   
  );
}