// src/ForgotPasswordModal.js

import React, { useState } from "react";
import Modal from "@shared/Modal";

const ForgotPasswordModal = ({ isOpen, onClose, onSubmit }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Enter your email to receive a password reset link:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <button type="submit" className="button">
          Send Reset Link
        </button>
      </form>
    </Modal>
  );
};

export default ForgotPasswordModal;
