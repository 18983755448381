import React, { useState, useEffect, useCallback } from "react";
import Fuse from "fuse.js";
import { useUser } from "@providers/user";
import { useApi } from "@providers/api";
import { formatDate } from "@util";
import "./style.css";
import BreadCrumbs from "@shared/BreadCrumb";
import Loading from "@shared/Loading";
import { useNavigate } from "react-router-dom";

const searchOptions = {
  keys: [
    "order_number",
    "doctor",
    "serial_numbers",
    "purchase_order_number",
    "office_name",
    "status",
  ],
};

export default function CreateInvoice() {
  const user = useUser();
  const api = useApi();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [fuse, setFuse] = useState(null);
  const [filteredOffice, setFilteredOffice] = useState(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [filteredByOffice, setFilteredByOffice] = useState(null);
  const [filteredBySearch, setFilteredBySearch] = useState(null);
  const [filteredByAccount, setFilteredByAccount] = useState(null);
  const [purchase_order_ids, setPurchaseOrderIds] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get("/orders/for-invoicing").then((response) => {
      if (!response) return;
      if (response.error) return console.error(response.error);

      setOrders(response);
      setFilteredOrders(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (orders) {
      setFuse(new Fuse(orders, searchOptions));
    }
  }, [orders]);

  // Combine filters and search
  useEffect(() => {
    if (filteredByOffice && filteredBySearch && filteredByAccount) {
      const filtered = filteredByOffice.filter((order) =>
        filteredBySearch.includes(order)
      );
      const filtered2 = filtered.filter((order) =>
        filteredByAccount.includes(order)
      );
      setFilteredOrders(filtered2);
    } else if (filteredByOffice && filteredBySearch) {
      const filtered = filteredByOffice.filter((order) =>
        filteredBySearch.includes(order)
      );
      setFilteredOrders(filtered);
    } else if (filteredByOffice && filteredByAccount) {
      const filtered = filteredByOffice.filter((order) =>
        filteredByAccount.includes(order)
      );
      setFilteredOrders(filtered);
    } else if (filteredBySearch && filteredByAccount) {
      const filtered = filteredBySearch.filter((order) =>
        filteredByAccount.includes(order)
      );
      setFilteredOrders(filtered);
    } else if (filteredByOffice) {
      setFilteredOrders(filteredByOffice);
    } else if (filteredBySearch) {
      setFilteredOrders(filteredBySearch);
    } else if (filteredByAccount) {
      setFilteredOrders(filteredByAccount);
    } else {
      setFilteredOrders(orders);
    }
  }, [filteredByOffice, filteredBySearch, filteredByAccount]);

  useEffect(() => {
    if (filteredOffice) {
      setSearchPlaceholder(`Search in ${filteredOffice.name}`);
    } else {
      setSearchPlaceholder("Search");
    }
  }, [filteredOffice]);

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    if (!search) {
      setFilteredBySearch(null);
      return;
    }

    const filtered = fuse.search(search);
    setFilteredBySearch(filtered.map((result) => result.item));
  };

  const handleFilterByOffice = (event) => {
    const officeId = event.target.value;
    if (!officeId) {
      setFilteredByOffice(null);
      setFilteredOffice(null);
      return;
    }

    setFilteredOffice(user.offices.find((office) => office.id == officeId));
    const filtered = orders.filter((order) => order.office_id == officeId);
    setFilteredByOffice(filtered);
  };

  const handleFilterByAccount = (event) => {
    const accountName = event.target.value;
    if (!accountName) {
      setFilteredByAccount(null);
      return;
    }

    const filtered = orders.filter(
      (order) => order.account_name == accountName
    );
    setFilteredByAccount(filtered);
  };

  const createInvoice = () => {
    api.post("/invoices", { invoice: { purchase_order_ids } }).then((res) => {
      if (!res) return;
      if (res.error) return alert(res.error);
      if (!res.id) return alert("Invoice creation failed.");

      navigate("/admin/invoices/" + res.id);
    });
  };

  const selectOrder = (order) => {
    if (purchase_order_ids.includes(order.id)) {
      setPurchaseOrderIds(purchase_order_ids.filter((id) => id !== order.id));
    } else {
      setPurchaseOrderIds([...purchase_order_ids, order.id]);
    }
  };

  const distinctAccountNames = [
    ...new Set(orders.map((order) => order?.account_name)),
  ];

  if (loading) return <Loading />;
  if (!orders.length)
    return (
      <div className="container">
        <BreadCrumbs
          items={[
            ["Home", "/"],
            ["Admin", "/admin"],
            ["Invoices", "/admin/invoices"],
            ["Create Invoice"],
          ]}
        />
        <div className="row">
          <div className="column column-50 column-offset-25">
            <div className="text-center">
              No orders are available for invoicing
            </div>
            <div className="text-center" style={{ fontSize: 14 }}>
              Orders are available for invoicing when they are marked as
              delivered and not currently invoiced.
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="container">
      <BreadCrumbs
        items={[
          ["Home", "/"],
          ["Admin", "/admin"],
          ["Invoices", "/admin/invoices"],
          ["Create Invoice"],
        ]}
      />
      <div className="row">
        <div className="column column-50">
          <input
            type="text"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
          />
        </div>
        <div className="column">
          <select onChange={handleFilterByAccount}>
            <option value="">Filter by Account</option>
            {distinctAccountNames.map((account_name) => (
              <option key={account_name} value={account_name}>
                {account_name}
              </option>
            ))}
          </select>
        </div>
        <div className="column">
          <select onChange={handleFilterByOffice}>
            <option value="">Filter by Office</option>
            {user?.offices?.map((office) => (
              <option key={office?.id} value={office?.id}>
                {office?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>Purchase Order Number</th>
            <th>Order Number</th>
            <th>Account</th>
            <th>Office</th>
            <th>Date Ordered</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders?.map((order) => (
            <tr
              style={{ cursor: "pointer" }}
              key={order.id}
              onClick={() => selectOrder(order)}
            >
              <td>
                <input
                  type="checkbox"
                  readonly
                  checked={purchase_order_ids.includes(order.id)}
                />
              </td>
              <td>{order.purchase_order_number}</td>
              <td>{order.order_number}</td>
              <td>{order.account_name}</td>
              <td>{order.office_name}</td>
              <td>{formatDate(order.created_at)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={createInvoice}>Create Invoice</button>
    </div>
  );
}
