import api from "@env/api";

export async function fetchOffices (token) {
  try {
    const response = await fetch(`${api}/offices/user`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    if (response.ok) {
      return response.json()
    } else {
      return { error: response.statusText }
    }
  } catch (error) {
    return { error: error.message }
  }
}

export async function validateToken (token) {
  try {
    const response = await fetch(`${api}/validate-token`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    return response.ok;
  }
  catch (error) {
    console.error('Error validating token', error);
    return false;
  }
}