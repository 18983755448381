import React from "react";
import FixedPair from "@shared/FixedPair";
import MailTo from "@shared/MailTo";

import { formatDateTime, formatPhoneNumber } from "@util";

export default function OrderDetails({ order }) {
  return (
    <>
      <h3>Order No. {order?.order_number}</h3>
      
      <br />

      <div className="row">
        <div className="column">
          <strong>Details</strong>
        </div>
        <div className="column">
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="column">
          <FixedPair
            strong
            padded
            columns
            label="Received"
            value={formatDateTime(order?.created_at)}
          />
          <FixedPair
            padded
            columns
            label="Office"
            value={
              <>
                <div>{order?.office?.name}</div>
                <div>
                  {formatPhoneNumber(order?.office?.phone, order?.office?.ext) || null}
                </div>
                <div>{order?.office?.address}</div>
              </>
            }
          />
        </div>
        <div className="column">
        <FixedPair
            label="Ordered By"
            value={
              <>
                <div>{order?.ordering_user?.name}</div>
                <div>
                  {formatPhoneNumber(
                    order?.ordering_user?.phone,
                    order?.ordering_user?.ext
                  )}
                </div>
                <div>
                  <MailTo email={order?.ordering_user?.email} />
                </div>
              </>
            }
          />
        </div>
      </div>
    </>
  );
}
