import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { useNavigate } from "react-router-dom";
import { useApi } from "@providers/api";
import { formatDate } from "@util";
import Loading from "@shared/Loading";
import BreadCrumb from "@shared/BreadCrumb";
import "./style.css";
import { formatPrice } from "@util";
const searchOptions = {
  keys: [
    "invoice_number",
    "invoice_purchase_orders.purchase_order.order_number",
    "invoice_purchase_orders.purchase_order.purchase_order_number",
  ],
  threshold: 0.3,
};

export default function Invoices() {
  const searchPlaceholder = "Search Invoice Numbers or Order Numbers";
  const api = useApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [fuse, setFuse] = useState(null);
  const [filteredBySearch, setFilteredBySearch] = useState(null);
  const [filteredByStatus, setFilteredByStatus] = useState(null);
  const [filteredByDate, setFilteredByDate] = useState(null);
  const [filteredByAccount, setFilteredByAccount] = useState(null);
  const [sort, setSort] = useState(null);

  useEffect(() => {
    api.get("/invoices").then((response) => {
      setInvoices(response);
      setFilteredInvoices(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (invoices) {
      setFuse(new Fuse(invoices, searchOptions));
    }
  }, [invoices]);

  // Combine filters and search
  useEffect(() => {
    let invoiceFilter = invoices;
    const statusOrder = ["pending", "sent", "paid", "cancelled"];

    if (filteredBySearch) {
      invoiceFilter = invoiceFilter.filter((invoice) =>
        filteredBySearch.includes(invoice)
      );
    }

    if (filteredByStatus) {
      invoiceFilter = invoiceFilter.filter((invoice) =>
        filteredByStatus.includes(invoice)
      );
    }

    if (filteredByDate) {
      invoiceFilter = invoiceFilter.filter((invoice) =>
        filteredByDate.includes(invoice)
      );
    }

    if (filteredByAccount) {
      invoiceFilter = invoiceFilter.filter((invoice) =>
        filteredByAccount.includes(invoice)
      );
    }

    if (sort) {
      invoiceFilter = invoiceFilter
        .sort((a, b) => {
          if (sort === "sent") {
            return new Date(b.sent_at) - new Date(a.sent_at);
          } else if (sort === "created") {
            return new Date(b.created_at) - new Date(a.created_at);
          } else if (sort === "paid") {
            return new Date(b.paid_at) - new Date(a.paid_at);
          } else if (sort === "status") {
            return (
              statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
            );
          } else {
            return -1;
          }
        })
        .filter((invoice) => {
          if (sort === "sent") {
            return invoice.sent_at;
          } else if (sort === "created") {
            return invoice.created_at;
          } else if (sort === "paid") {
            return invoice.paid_at;
          } else if (sort === "status") {
            return invoice.status;
          }
        });
    }

    setFilteredInvoices(invoiceFilter);
  }, [
    filteredBySearch,
    filteredByStatus,
    filteredByDate,
    filteredByAccount,
    sort,
  ]);

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    if (!search) {
      setFilteredBySearch(null);
      return;
    }

    const filtered = fuse.search(search);
    setFilteredBySearch(filtered.map((result) => result.item));
  };

  const handleStatusFilter = (event) => {
    const status = event.target.value;
    if (!status) {
      setFilteredByStatus(null);
      return;
    }

    const filtered = invoices.filter((invoice) => invoice.status === status);
    setFilteredByStatus(filtered);
  };

  const handleAccountFilter = (event) => {
    const account = event.target.value;
    if (!account) {
      setFilteredByAccount(null);
      return;
    }

    const filtered = invoices.filter(
      (invoice) => invoice.account.id == account
    );
    setFilteredByAccount(filtered);
  };

  function normalizeToMidnight(date) {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);
    return normalizedDate;
  }

  const handleFilterByDate = (event) => {
    const date = event.target.value;
    if (!date) {
      setFilteredByDate(null);
      return;
    }

    const filtered = invoices.filter((invoice) => {
      return normalizeToMidnight(invoice.sent_at) >= normalizeToMidnight(date);
    });
    setFilteredByDate(filtered);
  };

  const handleSort = (event) => {
    const sort = event.target.value;
    setSort(sort);
  };

  const exportCurrentView = () => {
    const data = filteredInvoices.map((invoice) => {
      const uniqueOrderNumbers = [
        ...new Set(
          invoice.invoice_purchase_orders.map(
            (ipo) => ipo.purchase_order.order_number
          )
        ),
      ];

      return {
        account: invoice.account.name,
        invoice_number: invoice.invoice_number,
        status: invoice.status,
        purchase_order_numbers: invoice.invoice_purchase_orders
          .map((ipo) => ipo.purchase_order.purchase_order_number)
          .join(", "),
        order_numbers: uniqueOrderNumbers.join(", "),
        sent_at: formatDate(invoice.sent_at),
        paid_at: formatDate(invoice.paid_at),
        amount: formatPrice(invoice.total),
      };
    });

    const escapeCsvValue = (value) => `"${String(value).replace(/"/g, '""')}"`;

    const csv = [
      Object.keys(data[0]).map(escapeCsvValue).join(","),
      ...data.map((invoice) =>
        Object.values(invoice).map(escapeCsvValue).join(",")
      ),
    ].join("\n");

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "invoices.csv";
    a.click();
  };

  if (loading) return <Loading />;

  if (!invoices?.length)
    return (
      <div className="container">
        <BreadCrumb items={[["Admin", "/admin"], "Invoices"]} />
        <div className="center-middle">
          <div className="text-center">
            <p>
              <strong>No invoices found.</strong>
            </p>
            <button onClick={() => navigate("/admin/invoices/create")}>
              Create an Invoice
            </button>
          </div>
        </div>
      </div>
    );

  // Create a Set to store unique account IDs
  const uniqueAccounts = new Set();

  // Filter invoices to get unique accounts
  const uniqueInvoices = invoices.filter((invoice) => {
    if (uniqueAccounts.has(invoice.account.id)) {
      return false;
    } else {
      uniqueAccounts.add(invoice.account.id);
      return true;
    }
  });

  return (
    <div className="container">
      <BreadCrumb items={[["Admin", "/admin"], "Invoices"]} />
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "21px",
          cursor: "pointer",
          textDecoration: "underline",
          color: "var(--primary-color)",
        }}
        onClick={exportCurrentView}
      >
        export current view
      </div>
      <div className="row">
        <div className="column column-33">
          <label>Search</label>
          <input
            type="text"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
          />
        </div>
        <div className="column">
          <label>Account</label>
          <select onChange={handleAccountFilter}>
            <option value="">All</option>
            {uniqueInvoices.map((invoice) => (
              <option key={invoice.account.id} value={invoice.account.id}>
                {invoice.account.name}
              </option>
            ))}
          </select>
        </div>
        <div className="column">
          <label>Status</label>
          <select onChange={handleStatusFilter}>
            <option value="">All</option>
            <option value="pending">Pending</option>
            <option value="sent">Sent</option>
            <option value="paid">Paid</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
        <div className="column">
          <label>Filter By Date Sent</label>
          <input type="date" onChange={handleFilterByDate} />
        </div>
        <div className="column">
          <label>Sort By</label>
          <select onChange={handleSort}>
            <option value="">None</option>
            <option value="sent">Last Sent At</option>
            <option value="created">Created At</option>
            <option value="paid">Paid At</option>
            <option value="status">Status</option>
          </select>
        </div>
        <div className="column text-right">
          <label>&nbsp;</label>
          <button onClick={() => navigate("/admin/invoices/create")}>
            Create Invoice
          </button>
        </div>
      </div>
      <table className="invoices-table">
        <thead>
          <tr>
            <th>Invoice Number</th>
            <th>Account</th>
            <th>Purchase Order Numbers</th>
            <th>Order Numbers</th>
            <th>Sent At</th>
            <th>Status</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {filteredInvoices?.map((invoice) => (
            <tr
              key={invoice.id}
              className="invoices--action-row"
              onClick={() => navigate(`/admin/invoices/${invoice.id}`)}
            >
              <td>{invoice.invoice_number}</td>
              <td>{invoice.account.name}</td>
              <td style={{ whiteSpace: "pre" }}>
                {invoice.invoice_purchase_orders
                  ?.map(
                    (invoice_purchase_order) =>
                      invoice_purchase_order?.purchase_order
                        ?.purchase_order_number
                  )
                  .join("\n")}
              </td>
              <td style={{ whiteSpace: "pre" }}>
                {invoice.invoice_purchase_orders
                  ?.map(
                    (invoice_purchase_order) =>
                      invoice_purchase_order?.purchase_order?.order_number
                  )
                  .join("\n")}
              </td>
              <td>{formatDate(invoice.sent_at) || "Unsent"}</td>
              <td>{invoice.status}</td>
              <td>{formatPrice(invoice.total)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
