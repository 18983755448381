import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { useUser } from "@providers/user";
import { useApi } from "@providers/api";
import { formatDate } from "@util";
import { useNavigate } from "react-router-dom";
import Loading from "@shared/Loading";

import "./style.css";

const searchOptions = {
  keys: [
    "order_number",
    "purchase_orders.doctor",
    "purchase_orders.purchase_order_products.serial_number",
    "purchase_orders.purchase_order_products.tracking_number",
    "purchase_orders.purchase_order_number",
  ],
};

export default function Orders() {
  const navigate = useNavigate();
  const user = useUser();
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [fuse, setFuse] = useState(null);
  const [filteredOffice, setFilteredOffice] = useState(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [filteredByOffice, setFilteredByOffice] = useState(null);
  const [filteredBySearch, setFilteredBySearch] = useState(null);

  useEffect(() => {
    api.get("/orders").then((response) => {
      setOrders(response);
      setFilteredOrders(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (orders) {
      setFuse(new Fuse(orders, searchOptions));
    }
  }, [orders]);

  // Combine filters and search
  useEffect(() => {
    if (filteredByOffice && filteredBySearch) {
      setFilteredOrders(
        filteredByOffice.filter((order) => filteredBySearch.includes(order))
      );
    } else if (filteredByOffice) {
      setFilteredOrders(filteredByOffice);
    } else if (filteredBySearch) {
      setFilteredOrders(filteredBySearch);
    } else {
      setFilteredOrders(orders);
    }
  }, [filteredByOffice, filteredBySearch]);

  useEffect(() => {
    if (filteredOffice) {
      setSearchPlaceholder(`Search in ${filteredOffice.name}`);
    } else {
      setSearchPlaceholder("Search");
    }
  }, [filteredOffice]);

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    if (!search) {
      setFilteredBySearch(null);
      return;
    }

    const filtered = fuse.search(search);
    setFilteredBySearch(filtered.map((result) => result.item));
  };

  const handleFilterByOffice = (event) => {
    const officeId = event.target.value;
    if (!officeId) {
      setFilteredByOffice(null);
      setFilteredOffice(null);
      return;
    }

    setFilteredOffice(user.offices.find((office) => office.id == officeId));
    const filtered = orders.filter((order) => order.office.id == officeId);
    setFilteredByOffice(filtered);
  };

  if (loading) {
    return <Loading />;
  }

  if (!orders?.length)
    return (
      <div className="container">
        <div className="center-middle">
          <div className="text-center">
            <p>
              <strong>No orders found.</strong>
            </p>
            <button onClick={() => navigate("/order")}>Create an Order</button>
          </div>
        </div>
      </div>
    );

  return (
    <div className="container padded">
      <div className="row">
        <div className="column">
          <input
            type="text"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
          />
        </div>
        {user?.offices?.length > 1 && (
          <div className="column">
            <select onChange={handleFilterByOffice}>
              <option value="">Filter by Office</option>
              {user?.offices?.map((office) => (
                <option key={office?.id} value={office?.id}>
                  {office?.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <table>
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Office</th>
            <th>PO Numbers</th>
            <th>Date Ordered</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders?.map((order) => (
            <tr
              key={order.id}
              className="orders--action-row"
              onClick={() => navigate(`/orders/${order.id}`)}
            >
              <td>{order.order_number}</td>
              <td>{order.office.name}</td>
              <td style={{ whiteSpace: "pre" }}>
                {order.purchase_orders
                  .map((po) => po.purchase_order_number)
                  .join("\n")}
              </td>
              <td>{formatDate(order.created_at)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
