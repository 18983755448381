import React from "react";
import { useOrder } from "../providers";

export default function SelectDateOfService({ onSelect }) {
  return (
    <div className="column">
      <label>
        Date of Service
        <input
          type="date"
          required
          onChange={(e) => onSelect(e.target.value)}
        />
      </label>
    </div>
  );
}