import React, { useState, useEffect, useRef } from "react";
import { useApi } from "@providers/api";
import {
  formatDate,
  formatDateTime,
  formatPrice,
  formatPhoneNumber,
} from "@util";
import { useParams } from "react-router-dom";
import BreadCrumb from "@shared/BreadCrumb";
import Loading from "@shared/Loading";
import FixedPair from "@shared/FixedPair";
import MailTo from "@shared/MailTo";

export default function ShowInvoice() {
  const api = useApi();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const load = () => {
    return api.get(`/invoices/${id}`).then((response) => {
      if (response.error || response?.ok === false) {
        const message = response.error || "";
        window.location.href = `/error?message=${message}&link-back=/admin/invoices/${id}`;
      }

      setInvoice(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    load();
    window.scrollTo(0, 0);
  }, []);

  const updateStatus = (e) => {
    const status = e.target.value;
    api.put(`/invoices/${id}`, { invoice: { status } }).then((response) => {
      if (response.error) return alert("Error updating status");
      load();
    });
  };

  const sendInvoice = () => {
    setLoading(true);
    api
      .post(`/invoices/deliver`, { invoice: { id } })
      .then(async (response) => {
        if (response.error) return alert(response.error);
        if (response.ok === false) return alert("Failed to send invoice");

        await load();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteInvoicePurchaseOrder = (id) => {
    if (!window.confirm("Are you sure you want to remove this PO?")) return;

    setLoading(true);
    api.delete(`/invoice_purchase_orders/${id}`).then(async () => {
      await load();
      setLoading(false);
    });
  };

  if (loading) return <Loading />;

  return (
    <div className="container">
      <BreadCrumb
        items={[["Invoicing", "/admin/invoices"], invoice?.invoice_number]}
      />

      <div className="row">
        <div className="column">
          <h3>Invoice No. {invoice.invoice_number}</h3>
        </div>
        <div className="column text-right">
          {(invoice?.status === "pending" && (
            <button type="button" className="button" onClick={sendInvoice}>
              Send Invoice
            </button>
          )) ||
            (invoice?.status !== "cancelled" && (
              <button type="button" className="button" onClick={sendInvoice}>
                Resend Invoice
              </button>
            ))}
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="column">
          <FixedPair
            strong
            padded
            columns
            label="Created"
            value={formatDateTime(invoice?.created_at)}
          />
          <FixedPair
            padded
            columns
            label="Total"
            value={formatPrice(invoice?.total)}
          />
          <FixedPair
            padded
            columns
            label="Paid At"
            value={
              invoice?.paid_at ? formatDateTime(invoice?.paid_at) : "Unpaid"
            }
          />
          <FixedPair
            padded
            columns
            label="Account"
            value={invoice?.account?.name}
          />
        </div>

        <div className="column">
          <FixedPair
            strong
            padded
            columns
            label="Updated"
            width={150}
            value={
              invoice?.created_at === invoice?.updated_at
                ? "N/A"
                : formatDateTime(invoice?.updated_at)
            }
          />

          <FixedPair
            padded
            columns
            label="Status"
            width={150}
            value={
              <select onChange={updateStatus} value={invoice?.status}>
                <option value="pending">Pending</option>
                <option value="sent">Sent</option>
                <option value="paid">Paid</option>
                <option value="cancelled">Cancelled</option>
              </select>
            }
          />

          <FixedPair
            padded
            columns
            label="Last Sent At"
            width={150}
            value={
              invoice?.sent_at ? formatDateTime(invoice?.sent_at) : "Unsent"
            }
          />
        </div>
      </div>

      <hr />

      <h4>Purchase Orders</h4>

      {invoice?.invoice_purchase_orders?.map((invoice_purchase_order) => (
        <div
          key={invoice_purchase_order?.id}
          style={{
            padding: 15,
            border: "1px solid #ccc",
            marginBottom: 30,
            boxShadow: "2px 2px 1px -1px rgba(0,0,0,0.2)",
            position: "relative",
          }}
        >
          <a
            onClick={deleteInvoicePurchaseOrder.bind(
              null,
              invoice_purchase_order?.id
            )}
            style={{
              position: "absolute",
              top: 5,
              right: 15,
              color: "red",
              cursor: "pointer",
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            &times;
          </a>
          <div key={invoice_purchase_order?.id} className="row">
            <div className="column">
              <FixedPair
                width={150}
                padded
                columns
                strong
                label="PO No."
                value={
                  invoice_purchase_order?.purchase_order?.purchase_order_number
                }
              />
              <FixedPair
                width={150}
                padded
                columns
                label="Office"
                value={
                  invoice_purchase_order?.purchase_order?.order?.office?.name
                }
              />
              <FixedPair
                width={150}
                padded
                columns
                label="Date Ordered"
                value={formatDate(
                  invoice_purchase_order?.purchase_order?.created_at
                )}
              />
            </div>
            <div className="column">
              <FixedPair
                width={150}
                padded
                columns
                label="Order No."
                value={invoice_purchase_order?.purchase_order?.order_number}
              />

              <FixedPair
                width={150}
                padded
                columns
                label="Ordered By"
                value={
                  <>
                    <div>
                      {
                        invoice_purchase_order?.purchase_order?.order
                          ?.ordering_user?.name
                      }
                    </div>
                    <div>
                      {formatPhoneNumber(
                        invoice_purchase_order?.purchase_order?.order
                          ?.ordering_user?.phone,
                        invoice_purchase_order?.purchase_order?.order
                          ?.ordering_user?.ext
                      )}
                    </div>
                    <div>
                      <MailTo
                        email={
                          invoice_purchase_order?.purchase_order?.order
                            ?.ordering_user?.email
                        }
                      />
                    </div>
                  </>
                }
              />
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Size</th>
                <th>Serial Number</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {invoice_purchase_order?.purchase_order?.purchase_order_products?.map(
                (pop) => (
                  <tr key={pop.id}>
                    <td>{pop.name}</td>
                    <td>{pop.product_size?.size}</td>
                    <td>{pop.serial_number}</td>
                    <td>{formatPrice(pop.price)}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}
