import React, { createContext, useContext, useState } from 'react';
import { ErrorFallback } from '@pages/error';

const ErrorContext = createContext();
export const useError = () => useContext(ErrorContext);

export default function ErrorProvider({ children }) {
  const [error, setError] = useState(null);

  const handleError = (error) => {
    setError(error);
  };

  const clearError = () => setError(null);

  return (
    <ErrorContext.Provider value={{ error, handleError, clearError }}>
      { error ? <ErrorFallback error={error}/> : children }
    </ErrorContext.Provider>
  );
}