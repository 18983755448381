// CustomAlert.js
import React, { useState, useEffect } from 'react';
import './style.css';

const CustomAlert = ({ message, style, duration }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  if (!visible) return null;

  return (
    <div className={`custom-alert ${style}`} onClick={() => setVisible(false)}>
      {message}
    </div>
  );
};

export default CustomAlert;