import React, { useState, useEffect, useCallback } from "react";
import Fuse from "fuse.js";
import { useUser } from "@providers/user";
import { useApi } from "@providers/api";
import "./style.css"
import BreadCrumb from "@shared/BreadCrumb";
import Loading from "@shared/Loading";
import { Link, useNavigate } from "react-router-dom";

const searchOptions = {
  keys: [
    "name",
    "email",
    "distributor_name",
  ],
};

export default function Vendors() {
  const user = useUser();
  const api = useApi();
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [fuse, setFuse] = useState(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [filteredBySearch, setFilteredBySearch] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get("/vendors").then((response) => {
      setVendors(response);
      setFilteredVendors(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (vendors) {
      setFuse(new Fuse(vendors, searchOptions));
    }
  }, [vendors]);

  // Combine filters and search
  useEffect(() => {
    if (filteredBySearch) {
      setFilteredVendors(filteredBySearch);
    } else {
      setFilteredVendors(vendors);
    }
  }, [filteredBySearch]);

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    if (!search) {
      setFilteredBySearch(null);
      return;
    }

    const filtered = fuse.search(search);
    setFilteredBySearch(filtered.map((result) => result.item));
  };

  if (loading || !user) {
    return <Loading />;
  }

  return (
    <div className="container">
      <BreadCrumb items={[["Admin", "/admin"], "Vendors"]} />
      <div className="row">
        <div className="column column-75">
          <input
            type="text"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
          />
        </div>
        <div className="column">
          <Link to="/admin/vendors/create" className="button">Create a New Vendor</Link>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Distributor Name</th>
          </tr>
        </thead>
        <tbody>
          {filteredVendors?.map((vendor) => (
            <tr key={vendor.id} className="orders--action-row" onClick={() => navigate(`/admin/vendors/${vendor.id}`)}>
              <td>{vendor.name}</td>
              <td>{vendor.email}</td>
              <td>{vendor.distributor_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
