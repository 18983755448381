// src/App.js

import React from "react";
import { createRoot } from 'react-dom/client';
import "./App.css";
import UserProvider from "@providers/user";
import ApiProvider from "@providers/api";
import Navigation from "./navigation";
import ErrorProvider from "@providers/error";
import CustomAlert from "@shared/CustomAlert";

const showAlert = (message, style = "red", duration = 3000) => {
  const alertContainer = document.createElement('div');
  document.body.appendChild(alertContainer);

  const root = createRoot(alertContainer);

  root.render(<CustomAlert message={message} duration={duration} style={style} />);

  setTimeout(() => {
    root.unmount();
    document.body.removeChild(alertContainer);
  }, duration + 100); // Ensure the component is unmounted after it's hidden
};


window.alert = showAlert;

function App() {
  return (
    <div className="App">
        <ErrorProvider>
          <UserProvider>
            <ApiProvider>
              <Navigation />
            </ApiProvider>
          </UserProvider>
        </ErrorProvider>
    </div>
  );
}

export default App;
