export default function submitOrderValidation(order) {
  const errors = [];

  if (!order.office_id) {
    errors.push("Select an ordering office");
  }
  if (!order.purchase_orders?.length) {
    errors.push("Create at least one purchase order");
    return;
  }

  order.purchase_orders?.forEach((purchase_order) => {
    if (!purchase_order.doctor) {
      errors.push("Select an ordering doctor");
    }
    if (!purchase_order.service_date) {
      errors.push("Select a date of service");
    }
    if (!purchase_order.shipping_address) {
      errors.push("Enter the shipping address");
    }
    if (!purchase_order.contact_name) {
      errors.push("Enter a contact name");
    }
    if (!purchase_order.contact_email) {
      errors.push("Enter a contact email");
    }
    if (!purchase_order.contact_phone) {
      errors.push("Enter a contact phone");
    }
    if (!purchase_order.product_sizes || !purchase_order.product_sizes.length) {
      errors.push("Add at least one product for each purchase order");
    }
  });

  return errors;
}
