import React from "react";

export default function Cancelled() {
  return (
    <span
      style={{
        display: "inline-block",
        width: 12,
        height: 12,
        marginRight: 10,
        borderRadius: 10,
        backgroundColor: "rgb(255,69,0)",
      }}
    ></span>
  );
}
