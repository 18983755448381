import React, { useState, useEffect } from "react";
import { useOrder } from "../providers";
import SelectOrderingDoctor from "./SelectOrderingDoctor";
import SelectDateOfService from "./SelectDateOfService";
import SelectOrderContact from "./SelectOrderContact";
import PurchaseOrderContactFields from "./OrderContactFields";
import ShippingAddress from "./ShippingAddress";
import OrderProducts from "./OrderProducts";
import Totals from "./Totals";
export default function PurchaseOrder({ purchase_order, index }) {
  const {
    office,
    updatePurchaseOrder,
    products,
    addProduct,
    removePurchaseOrder,
  } = useOrder();
  const [purchaseOrder, setPurchaseOrder] = useState(purchase_order);
  const [product, setProduct] = useState(null);
  const [product_sizes, setProductSizes] = useState([]);

  const handleAttributeUpdate = (attribute, value) => {
    setPurchaseOrder((prev) => ({
      ...prev,
      [attribute]: value,
    }));
  };

  useEffect(() => {
    updatePurchaseOrder(index, purchaseOrder);
  }, [purchaseOrder]);

  const handleContactSelect = (contact) => {
    if (contact === "new") {
      handleAttributeUpdate("contact_name", "");
      handleAttributeUpdate("contact_email", "");
      handleAttributeUpdate("contact_phone", "");
      handleAttributeUpdate("contact_phone_extension", "");
    } else {
      const selectedContact = office.office_users.find(
        (office_user) => office_user.user?.id === contact?.id
      );
      handleAttributeUpdate("contact_name", selectedContact?.user?.name);
      handleAttributeUpdate("contact_email", selectedContact?.user?.email);
      handleAttributeUpdate("contact_phone", selectedContact?.user?.phone);
      handleAttributeUpdate(
        "contact_phone_extension",
        selectedContact?.user?.phone_extension
      );
    }
  };

  const handleProductAdd = (product, e) => {
    e.preventDefault();
    const product_size_id = e.target.value;
    if (!product_size_id) {
      return;
    }
    e.target.value = "";

    const products = purchaseOrder.products;
    const product_size = product.product_sizes.find(
      (size) => size.id === Number(product_size_id)
    );
    const productToAdd = {
      product_id: product.id,
      product_size_id: Number(product_size_id),
      name: product.name,
      price: product_size.price,
      size: product_size.size,
      oid: Date.now(),
    };
    handleAttributeUpdate("products", [...products, productToAdd]);
  };

  const removeProduct = (product) => {
    const products = purchaseOrder.products.filter(
      (p) => p.oid !== product.oid
    );
    handleAttributeUpdate("products", products);
  };

  const handleSelectProduct = (product_id) => {
    setProduct(products.find((p) => p.id === Number(product_id)) || null);
  };

  return (
    <div style={{ border: "1px solid #ccc", padding: 10, borderRadius: 5 }}>
      <div className="row">
        <div className="column">
          Purchase Order{" "}
          {purchase_order?.purchase_order_number
            ? `Number: ${purchase_order?.purchase_order_number}`
            : null}
        </div>
        <div className="column text-right">
          <a onClick={() => removePurchaseOrder(purchase_order)}>
            Delete Purchase Order
          </a>
        </div>
      </div>
      <br />
      <div className="row">
        <ShippingAddress handleAttributeUpdate={handleAttributeUpdate} />
      </div>
      <div className="row">
        <div className="column">
          <label>
            Purchase Order No.
            <input
              type="text"
              required
              autoFocus
              onChange={(e) =>
                handleAttributeUpdate("purchase_order_number", e.target.value)
              }
            />
          </label>
        </div>
        <SelectOrderingDoctor
          onSelect={(doctor) => handleAttributeUpdate("doctor", doctor)}
        />
        <SelectDateOfService
          index={index}
          onSelect={(date) => handleAttributeUpdate("service_date", date)}
        />
        <SelectOrderContact
          index={index}
          onSelect={(contact) => handleContactSelect(contact)}
        />
      </div>
      <PurchaseOrderContactFields
        handleAttributeUpdate={handleAttributeUpdate}
        purchase_order={purchase_order}
      />
      <div className="row">
        <div className="column column-50">
          <label htmlFor="product">Product</label>
          <select
            id="product"
            onChange={(e) => handleSelectProduct(e.target.value)}
          >
            <option value="">Select Product</option>
            {products?.map((product) => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </select>
        </div>
        <div className="column column-50">
          <label htmlFor="product_size">Product Size</label>
          <select
            id="product_size"
            onChange={(e) => handleProductAdd(product, e)}
          >
            <option value="">Select Product Size</option>
            {product?.product_sizes?.map((size) => (
              <option key={size.id} value={size.id}>
                {size.size}
              </option>
            ))}
          </select>
        </div>
      </div>
      <OrderProducts
        purchase_order={purchase_order}
        index={index}
        removeProduct={removeProduct}
      />
      <Totals purchase_order={purchase_order} />
    </div>
  );
}
