import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "@providers/api";
import BreadCrumb from "@shared/BreadCrumb";

export default function CreateOffice() {
  const api = useApi();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    api.get("/accounts").then((accounts) => {
      setAccounts(accounts);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    api.post(`/offices`, { office: data }).then((office) => {
      window.location = `/admin/offices/${office.id}`;
    });
  };

  return (
    <div className="container">
      <BreadCrumb
        items={[
          ["Admin", "/admin"],
          ["Offices", "/admin/offices"],
          "Create Office",
        ]}
      />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Name</label>
            <input required type="text" name="name" id="name" />
          </div>
          <div className="column">
            <label htmlFor="address">Address</label>
            <input type="text" name="address" id="address" />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <label htmlFor="phone">Phone</label>
            <input required type="tel" name="phone" id="phone" />
          </div>
          <div className="column">
            <label htmlFor="account_id">Account</label>
            <select required name="account_id" id="account_id">
              <option value="">Select Account</option>
              {accounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button type="submit">Save</button>
      </form>
    </div>
  );
}
