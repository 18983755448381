import React, { useState, useEffect } from "react";
import { useOrder } from "../providers";

export default function SelectOrderingDoctor({ onSelect }) {
  const { office } = useOrder();
  const [doctors, setDoctors] = useState([]);

  const handleSelect = (e) => {
    const doctor = doctors?.find((d) => d.id == e.target.value);
    onSelect(doctor);
  };

  useEffect(() => {
    setDoctors(
      office?.office_users?.filter((office_user) =>
        office_user.user?.user_roles?.find(
          (user_role) => user_role.role === "doctor"
        )
      )?.map(office_user => office_user.user)
    )
  },
  [office]);


  useEffect(() => {
    if (!doctors?.length) return ;

    onSelect(doctors[0].name);
  }, [doctors])

  const handleDoctorChange = (e) => {
    const doctor = doctors.find((d) => d.id == e.target.value);
    onSelect(doctor?.name);
  }

  if (!office) return null;

  return (
    <div className="column">
      <label>
        Ordering Doctor
        <select onChange={handleDoctorChange}>
          {doctors?.map((doctor) => (
            <option key={doctor.id} value={doctor.id}>
              {doctor.name}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
}
