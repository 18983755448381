import React, { useState } from "react";
import { useApi } from "@providers/api";
import { formatPhoneNumber, formatDate } from "@util";
import FixedPair from "@shared/FixedPair";
import MailTo from "@shared/MailTo";

import "./style.css";

// This component is used to render a single order as an Item in a List View.
// It includes a subset of order details, and purchase_orders contained within the order.

export default function EditPurchaseOrder({ purchase_order, order, reload }) {
  const api = useApi();
  const updatePurchaseOrder = async (field, value) => {
    const response = await api.put(`/purchase_orders/${purchase_order?.id}`, {
      purchase_order: { [field]: value },
    });

    if (response?.id) {
      reload();
    }
  };

  const updatePurchaseOrderProduct = async (
    purchase_order_product,
    field,
    value
  ) => {
    const response = await api.put(
      `/purchase_order_products/${purchase_order_product?.id}`,
      {
        purchase_order_product: { [field]: value },
      }
    );

    if (response?.id) {
      reload();
    }
  };

  if (!purchase_order) {
    return <>...Loading</>;
  }

  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: 10,
        margin: "10px 0 30px",
      }}
    >
      <div className="row">
        <div className="column">
          <FixedPair
            strong
            padded
            columns
            width={200}
            label={<strong>PO Number</strong>}
            value={<strong>{purchase_order?.purchase_order_number}</strong>}
          />
          <FixedPair
            padded
            label="Status"
            columns
            width={200}
            value={
              <select
                value={purchase_order?.status}
                onChange={(e) => updatePurchaseOrder("status", e.target.value)}
              >
                <option value="pending">Pending</option>
                <option value="shipped">Shipped</option>
                <option value="delivered">Delivered</option>
                <option value="cancelled">Cancelled</option>
              </select>
            }
          />
          <FixedPair
            padded
            label="Tracking No."
            columns
            width={200}
            value={
              <input
                type="text"
                placeholder="Tracking Number"
                defaultValue={purchase_order?.tracking_number || ""}
                onBlur={(e) =>
                  updatePurchaseOrder("tracking_number", e.target.value)
                }
              />
            }
          />
          <FixedPair
            padded
            label="Date Shipped"
            columns
            width={200}
            value={
              <input
                type="date"
                defaultValue={purchase_order?.date_shipped || ""}
                onBlur={(e) =>
                  updatePurchaseOrder("date_shipped", e.target.value)
                }
              />
            }
          />
          <FixedPair
            padded
            columns
            width={200}
            label="Shipping Address"
            value={
              <textarea
                value={purchase_order?.shipping_address}
                onBlur={(e) =>
                  updatePurchaseOrder("shipping_address", e.target.value)
                }
              />
            }
          />
          <FixedPair
            padded
            columns
            width={200}
            label="Service Date"
            value={formatDate(purchase_order?.service_date)}
          />
          <FixedPair
            columns
            width={200}
            padded
            label="Doctor"
            value={purchase_order?.doctor}
          />
          <FixedPair
            columns
            width={200}
            padded
            label="Contact"
            value={
              <div>
                <div>{purchase_order?.contact_name}</div>
                <div>{formatPhoneNumber(purchase_order?.contact_phone)}</div>
                <div>
                  <MailTo
                    email={purchase_order?.contact_email}
                    subject={`Purchase Order No. ${purchase_order?.purchase_order_number}`}
                  />
                </div>
              </div>
            }
          />
        </div>
        <div className="column">
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Size</th>
                <th>Serial Number</th>
              </tr>
            </thead>
            <tbody>
              {purchase_order?.purchase_order_products?.map(
                (purchase_order_product, i) => (
                  <tr key={purchase_order_product.id}>
                    <td>{purchase_order_product.name}</td>
                    <td>{purchase_order_product?.size}</td>
                    <td>
                      <input
                        type="text"
                        defaultValue={
                          purchase_order_product.serial_number || ""
                        }
                        onBlur={(e) =>
                          updatePurchaseOrderProduct(
                            purchase_order_product,
                            "serial_number",
                            e.target.value
                          )
                        }
                        placeholder="Serial Number"
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
