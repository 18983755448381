import React, { useState, useEffect, useCallback } from "react";
import Fuse from "fuse.js";
import { useApi } from "@providers/api";
import { useNavigate } from "react-router-dom";
import "./style.css";
import BreadCrumb from "@shared/BreadCrumb";

const searchOptions = {
  keys: ["name", "offices.name"],
};

export default function Accounts() {
  const navigate = useNavigate();
  const api = useApi();
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [filteredBySearch, setFilteredBySearch] = useState(null);
  const [fuse, setFuse] = useState(null);
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search");
  const [showDisabledAccounts, setShowDisabledAccounts] = useState(false);
  const [disabledAccounts, setDisabledAccounts] = useState(false);

  useEffect(() => {
    api.get("/accounts").then((response) => {
      setAccounts(response.filter((account) => account.enabled));
      setFilteredAccounts(response.filter((account) => account.enabled));
      setDisabledAccounts(response.filter((account) => !account.enabled));
    });
  }, []);

  useEffect(() => {
    if (showDisabledAccounts) {
      setFilteredAccounts(disabledAccounts);
    } else {
      setFilteredAccounts(accounts);
    }
  }, [showDisabledAccounts]);

  useEffect(() => {
    if (accounts) {
      setFuse(new Fuse(accounts, searchOptions));
    }
  }, [accounts]);

  // Combine filters and search
  useEffect(() => {
    if (filteredBySearch) {
      setFilteredAccounts(filteredBySearch);
    } else {
      setFilteredAccounts(accounts);
    }
  }, [filteredBySearch]);

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    setShowDisabledAccounts(false);
    if (!search) {
      setFilteredBySearch(null);
      return;
    }

    const filtered = fuse.search(search);
    setFilteredBySearch(filtered.map((result) => result.item));
  };

  return (
    <div className="container">
      <BreadCrumb items={[["Admin", "/admin"], "Accounts"]} />
      <div>
        <label
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "10px",
            right: "20px",
            fontSize: "1.3rem",
            fontWeight: 600,
          }}
          htmlFor="disabled"
          title="Click to change"
        >
          {showDisabledAccounts
            ? "Showing Disabled Accounts"
            : "Showing Enabled Accounts"}
        </label>
        <input
          style={{ display: "none" }}
          id="disabled"
          type="checkbox"
          onClick={(e) => setShowDisabledAccounts(e.target.checked)}
        />
      </div>
      <div className="row">
        <div className="column column-75">
          <input
            type="text"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
          />
        </div>
        <div className="column column-25 text-right">
          <button onClick={() => navigate("/admin/accounts/create")}>
            Create A New Account
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {filteredAccounts?.map((account) => (
            <tr
              key={account.id}
              className="orders--action-row"
              onClick={() => navigate(`/admin/accounts/${account.id}`)}
            >
              <td>{account.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
