import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApi } from "@providers/api";
import Fuse from "fuse.js";

const userSearchOptions = {
  keys: ["name", "email", "phone"],
};

const officeSearchOptions = {
  keys: ["name", "address"],
};

const productSearchOptions = {
  keys: ["name"],
};

const orderSearchOptions = {
  keys: [
    "order_number",
    "ordering_user.name",
    "purchase_orders.doctor",
    "office.name",
    "purchase_orders.purchase_order_products.serial_number",
    "purchase_orders.tracking_number",
    "purchase_orders.purchase_order_number",
  ],
  includeMatches: true,
  threshold: 0.3,
};

const formatKeyName = (key) => {
  return key
    .replace("ordering_user.name", "Ordering User")
    .replace("purchase_orders.doctor", "Doctor")
    .replace("office.name", "Office")
    .replace("purchase_orders.purchase_order_products.serial_number", "SN")
    .replace("purchase_orders.tracking_number", "Tracking")
    .replace("purchase_orders.purchase_order_number", "Purchase Order");
};

export default function Admin() {
  const api = useApi();
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [offices, setOffices] = useState([]);
  const [fuseProducts, setFuseProducts] = useState(null);
  const [fuseOrders, setFuseOrders] = useState(null);
  const [fuseUsers, setFuseUsers] = useState(null);
  const [fuseOffices, setFuseOffices] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredOffices, setFilteredOffices] = useState([]);

  useEffect(() => {
    api.get("/products").then((products) => {
      setProducts(products);
    });

    api.get("/orders").then((orders) => {
      setOrders(orders);
    });

    api.get("/users").then((users) => {
      setUsers(users);
    });

    api.get("/offices").then((offices) => {
      setOffices(offices);
    });
  }, []);

  useEffect(() => {
    if (products) {
      setFuseProducts(new Fuse(products, productSearchOptions));
    }
  }, [products]);

  useEffect(() => {
    if (orders) {
      setFuseOrders(new Fuse(orders, orderSearchOptions));
    }
  }, [orders]);

  useEffect(() => {
    if (users) {
      setFuseUsers(new Fuse(users, userSearchOptions));
    }
  }, [users]);

  useEffect(() => {
    if (offices) {
      setFuseOffices(new Fuse(offices, officeSearchOptions));
    }
  }, [offices]);

  const handleProductSearch = (event) => {
    const search = event.target.value;
    if (!search) {
      setFilteredProducts([]);
    } else {
      setFilteredProducts(
        fuseProducts.search(search).map((result) => result.item)
      );
    }
  };

  const handleOrderSearch = (event) => {
    const search = event.target.value;
    if (!search) {
      setFilteredOrders([]);
    } else {
      setFilteredOrders(fuseOrders.search(search));
    }
  };

  const handleUserSearch = (event) => {
    const search = event.target.value;
    if (!search) {
      setFilteredUsers([]);
    } else {
      setFilteredUsers(fuseUsers.search(search).map((result) => result.item));
    }
  };

  const handleOfficeSearch = (event) => {
    const search = event.target.value;
    if (!search) {
      setFilteredOffices([]);
    } else {
      setFilteredOffices(
        fuseOffices.search(search).map((result) => result.item)
      );
    }
  };

  const handleInputBlur = (event) => {
    if (event.target.tagName === "A") {
      return;
    }

    setFilteredProducts([]);
    setFilteredOrders([]);
    setFilteredUsers([]);
    setFilteredOffices([]);
  }


  return (
    <div className="container padded" onClick={handleInputBlur}>
      <h3>EWCC Admin Portal</h3>

      <div className="row">
        <div className="column">
          <h4>Products</h4>
          <div className="autocomplete">
            <input
              type="text"
              placeholder="Search Products"
              onChange={handleProductSearch}
              onBlur={e => e.target.value = ""}
            />
            {filteredProducts.length > 0 ? (
              <ul>
                {filteredProducts.map((product) => (
                  <li key={product.id}>
                    <Link to={`/admin/products/${product.id}`}>
                      » {product.name}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
          <Link to="/admin/products">Manage Products</Link>
        </div>
        <div className="column">
          <h4>Orders</h4>
          <div className="autocomplete">
            <input
              type="text"
              placeholder="Search Orders"
              onChange={handleOrderSearch}
              onBlur={e => e.target.value = ""}
            />
            {filteredOrders.length > 0 ? (
              <ul>
                {filteredOrders.map((search) => (
                  <li key={search.item.id} style={{fontSize: 14}}>
                    <Link to={`/admin/orders/${search.item.id}`}>
                      {formatKeyName(search.matches[0].key)} »{" "}
                      {search.matches[0].value}
                      <div style={{fontSize: 12}} >
                        Order No. {search.item.order_number}
                      </div>
                      <div style={{fontSize: 12}} >
                        PO Numbers {search.item.purchase_orders.map((purchase_order) => purchase_order.purchase_order_number). join(", ")}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
          <Link to="/admin/orders">Manage Orders</Link>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="column">
          <h4>Offices</h4>
          <div className="autocomplete">
          <input
            type="text"
            placeholder="Search Offices"
            onChange={handleOfficeSearch}
            onBlur={e => e.target.value = ""}
          />
          {filteredOffices.length > 0 ? (
            <ul>
              {filteredOffices.map((office) => (
                <li key={office.id}>
                  <Link to={`/admin/offices/${office.id}`}>
                    » {office.name}
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}
          </div>
          <Link to="/admin/offices">Manage Offices</Link>
        </div>
        <div className="column">
          <h4>Users</h4>
          <div className="autocomplete">
          <input
            type="text"
            placeholder="Search Users"
            onChange={handleUserSearch}
            onBlur={e => e.target.value = ""}
          />
          {filteredUsers.length > 0 ? (
            <ul>
              {filteredUsers.map((user) => (
                <li key={user.id}>
                  <Link to={`/admin/users/${user.id}`}>
                    » {user.name}
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}
          </div>
          <Link to="/admin/users">Manage Users</Link>
        </div>
      </div>
    </div>
  );
}
