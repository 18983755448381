import React from "react";
const Video = ({ src, title }) => (
  <>
    <h4>{title}</h4>

    <div
      style={{
        position: "relative",
        paddingBottom: "63.38028169014085%",
        height: 0,
      }}
    >
      <iframe
        src={src}
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </div>
  </>
);

export default function Help() {
  return (
    <div className="container">
      <h1>&nbsp;</h1>
      <div className="row">
        <div className="column">
          <Video
            src={
              "https://www.loom.com/embed/fac100ea37d14442873b8f7bdf3754ec?sid=ee1bd80b-6d44-4fcc-9e76-7671abcee7a5"
            }
            title={"Creating and Assigning Accounts"}
          />
        </div>
        <div className="column">
          <Video
            src={
              "https://www.loom.com/embed/ef8290d3c9254e19b42a8467281991dc?sid=32040cde-1d19-40ef-9f69-f49da660d1f6"
            }
            title={"Adding a new product from a new vendor"}
          />
        </div>
      </div>
    </div>
  );
}
