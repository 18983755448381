import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useApi } from "@providers/api";
import { useUser } from "@providers/user";

import BreadCrumb from "@shared/BreadCrumb";

export default function User() {
  const api = useApi();
  const { offices } = useUser();
  const [user, setUser] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { id } = useParams();

  const load = () =>
    api.get(`/users/${id}?include=office_users`).then((user) => {
      setUser(user);
      setSelectedRole(user.user_roles[0].role);
      setSelectedAccount(user.account_id);
    });

  useEffect(() => {
    load();
    api.get("/accounts").then((response) => {
      setAccounts(response);
    });
  }, [id]);

  if (!user || !accounts.length) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    api.put(`/users/${id}`, { user: data }).then(async (response) => {
      if (response?.id) {
        await load();
        alert("User updated successfully", "green");
      } else {
        alert("An error occurred");
      }
    });
  };

  const addOffice = () => {
    const formData = new FormData();
    const office_id = document.getElementById("office").value;
    api
      .post(`/office_users`, { office_user: { office_id, user_id: user.id } })
      .then((response) => {
        if (response?.id) {
          document.getElementById("office").value = "";
          load();
        } else {
          alert("An error occurred");
        }
      });
  };

  const handleDeleteOfficeUser = (office_user_id) => {
    if (window.confirm("Are you sure you want to remove this office?")) {
      api.delete(`/office_users/${office_user_id}`).then((response) => {
        if (response?.id) {
          load();
        }
      });
    }
  };

  const superUser = selectedRole === "super";
  const accountingUser = selectedRole === "accounting";
  const missingOffices = !user.office_users.length;

  return (
    <div className="container">
      <BreadCrumb
        items={[["Admin", "/admin"], ["Users", "/admin/users"], user.name]}
      />
      {missingOffices && !superUser && (
        <div style={{ color: "red", padding: 10 }}>
          This user is missing offices. Please assign them to an office to
          enable them to log in
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" defaultValue={user.name} />
          </div>
          <div className="column">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              defaultValue={user.email}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <label htmlFor="role">Role</label>
            <select
              name="role"
              id="role"
              defaultValue={user.user_roles[0].role}
              onChange={(e) => setSelectedRole(e.target.value)}
              required
            >
              <option value="admin">Admin</option>
              <option value="doctor">Doctor</option>
              <option value="accounting">Accounting</option>
              <option value="super">⚠️ Super User</option>
            </select>
          </div>
          {!superUser && (
            <>
              <div className="column">
                <label htmlFor="account_id">Account</label>
                <select
                  required
                  name="account_id"
                  id="account_id"
                  value={selectedAccount}
                  onChange={(e) => setSelectedAccount(e.target.value)}
                >
                  <option value="">Account</option>
                  {accounts.map((account) => {
                    return <option value={account.id}>{account.name}</option>;
                  })}
                </select>
              </div>
            </>
          )}
        </div>
        <button type="submit">Update</button>
      </form>
      <hr />
      <h4>Update Password</h4>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.target);
          const data = Object.fromEntries(formData);
          api
            .put(`/users/${id}/update-password`, { user: data })
            .then((response) => {
              if (response?.id) {
                alert("Password updated successfully", "green");
              } else {
                if (response?.password_confirmation) {
                  alert("Password and confirmation do not match");
                } else {
                  alert("An error occurred");
                }
              }
            });
        }}
      >
        <div>
          <label htmlFor="password">Password</label>
          <input required type="password" name="password" id="password" />
        </div>
        <div>
          <label htmlFor="password">Confirm Password</label>
          <input
            required
            type="password"
            name="password_confirmation"
            id="password_confirmation"
          />
        </div>
        <button type="submit">Update Password</button>
      </form>
      <hr />
      {superUser || accountingUser ? null : (
        <>
          <h3>Assigned Offices</h3>
          {user?.office_users?.map((office_user) => {
            return (
              <div>
                <a
                  onClick={() => handleDeleteOfficeUser(office_user.id)}
                  style={{
                    display: "inline-block",
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    backgroundColor: "firebrick",
                    color: "white",
                    textAlign: "center",
                    lineHeight: "24px",
                    textDecoration: "none",
                    marginRight: "10px",
                    fontWeight: "bold",
                  }}
                >
                  &times;
                </a>
                <Link to={`/admin/offices/${office_user.office.id}`}>
                  {office_user.office.name}
                </Link>
              </div>
            );
          })}
          <br />

          <div className="row">
            <div className="column">
              <select name="office" id="office">
                <option value="">Add another office</option>
                {offices
                  .filter(
                    (office) =>
                      !user.office_users.find(
                        (office_user) => office_user.office.id === office.id
                      ) && user.account_id === office.account_id
                  )
                  .map((office) => {
                    return <option value={office.id}>{office.name}</option>;
                  })}
              </select>
            </div>
            <div className="column">
              <button onClick={addOffice}>Add Office</button>
            </div>
          </div>
        </>
      )}
      <hr />
      {user.active ? (
        <DisableUser user={user} load={load} />
      ) : (
        <EnableUser user={user} load={load} />
      )}
    </div>
  );
}

const EnableUser = ({ user, load }) => {
  const api = useApi();

  return (
    <>
      <h3>Enable User</h3>
      <p>
        Enabling a user will allow them to log in. They will be able to place
        orders and access their account
      </p>
      <button
        onClick={() => {
          if (window.confirm("Are you sure you want to enable this user?")) {
            api
              .put(`/users/${user.id}`, { user: { active: true } })
              .then((response) => {
                if (response?.id) {
                  load();
                } else {
                  alert("An error occurred");
                }
              });
          }
        }}
      >
        Enable User
      </button>
    </>
  );
};

const DisableUser = ({ user, load }) => {
  const api = useApi();

  return (
    <>
      <h3>Disable User</h3>
      <p>
        Disabling a user will prevent them from logging in. Their orders will
        still be accessible by other people in their office. Furthermore, this
        user will still be reflected as an the ordering user on any orders they
        previously placed
      </p>
      <button
        onClick={() => {
          if (window.confirm("Are you sure you want to disable this user?")) {
            api
              .put(`/users/${user.id}`, { user: { active: false } })
              .then((response) => {
                if (response?.id) {
                  load();
                } else {
                  alert("An error occurred");
                }
              });
          }
        }}
      >
        Disable User
      </button>
    </>
  );
};
