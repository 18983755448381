import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "@providers/api";
import BreadCrumb from "@shared/BreadCrumb";

export default function CreateVendor() {
  const api = useApi();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    api.post(`/vendors`, { vendor: data }).then((vendor) => {
      if (vendor.errors) {
        alert("We're sorry, but we couldn't create the vendor. Please try again.");
        return;
      }

      navigate(`/admin/vendors`);
    });
  };

  return (
    <div className="container">
      <BreadCrumb
        items={[
          ["Admin", "/admin"],
          ["Vendors", "/admin/vendors"],
          "Create Vendor",
        ]}
      />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Name</label>
            <input required type="text" name="name" id="name" />
          </div>
          <div className="column">
            <label htmlFor="email">Email</label>
            <input required type="email" name="email" id="email" />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <label htmlFor="distributor_name">Distributor Name</label>
            <input required defaultValue={"IMS Medical Supply"} type="text" name="distributor_name" id="distributor_name" />
          </div>
          <div className="column">
            <label htmlFor="distributor_contact">Distributor Contact</label>
            <input required defaultValue={"Michael Schubyn"} type="text" name="distributor_contact" id="distributor_contact" />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <label htmlFor="distributor_number">Distributor Number</label>
            <input required defaultValue={"480-620-5851"} type="text" name="distributor_number" id="distributor_number" />
          </div>
          <div className="column">
            <label htmlFor="sales_rep">Sales Rep</label>
            <input required defaultValue={"MDS"} type="text" name="sales_rep" id="sales_rep" />
          </div>
        </div>

        <button type="submit">Save</button>
      </form>
    </div>
  );
}
