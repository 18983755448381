import React from "react";
import SelectOrderingOffice from "./components/SelectOrderingOffice";
import PurchaseOrders from "./components/PurchaseOrders";
import OrderProvider from "./providers";
import Form from "./components/Form";
import "./style.css";

const OrderForm = () => {
  return (
    <OrderProvider>
      <div className="container" style={{ padding: 10, marginTop: 50 }}>
        <Form>
          <div className="row">
            <SelectOrderingOffice />
          </div>
          <PurchaseOrders />
        </Form>
      </div>
    </OrderProvider>
  );
};

export default OrderForm;
