import React from "react";
import { useOrder } from "../providers";
import { useUser } from "@providers/user";

const Header = () => {
  const { setSkipComms, skipComms } = useOrder();
  const { user } = useUser();
  const superUser = !!user?.roles?.find((r) => r === "super");

  if (superUser) {
    return (
      <div className="row">
        <div className="column">
          <h4>Place an Order</h4>
        </div>
        <div className="column text-right">
          <label title="Record Only: Does not send the order to the vendor, or send any communications to clients">
            Record Only &nbsp;&nbsp;
            <input
              type="checkbox"
              onChange={(e) => setSkipComms(e.target.checked)}
              checked={skipComms}
            />
          </label>
        </div>
      </div>
    );
  }

  return <h4>Place an Order</h4>;
};

const Form = ({ children }) => {
  const { submitOrder, createPurchaseOrder, office } = useOrder();
  if (!office) {
    return (
      <form onSubmit={submitOrder} className="grid-container text-left">
        <Header />
        {children}
      </form>
    );
  }

  return (
    <form onSubmit={submitOrder} className="grid-container text-left">
      <Header />
      {children}
      <hr />
      <div className="float-left">
        <button
          className="button button-outline"
          type="button"
          onClick={createPurchaseOrder}
        >
          Add Another Purchase Order
        </button>
      </div>
      <div className="float-right">
        <button className="button success" type="submit">
          Submit Order
        </button>
      </div>
      <div className="clearfix"></div>
    </form>
  );
};

export default Form;
