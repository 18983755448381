import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "@providers/api";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "@shared/BreadCrumb";
import Modal from "@shared/Modal";
import { chunkArray } from "@util";

export default function Product() {
  const api = useApi();
  const [product, setProduct] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [showProductSizeModal, setShowProductSizeModal] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    api.get("/accounts").then((response) => {
      setAccounts(response);
    });

    api.get("/vendors").then((response) => {
      setVendors(response);
    });
  }, []);

  useEffect(() => {
    api.get(`/products/${id}`).then((product) => {
      // if the product is not found, show a 404 message
      if (product.status === 404) {
        navigate("/404?message=Product+not+found");
        return;
      }
      setProduct(product);
    });
  }, [id]);

  const deleteProduct = () => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      api.delete(`/account_products/${id}`).then(() => {
        navigate("/admin/products");
      });
    }
  };

  const handleProductUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    api.put(`/products/${id}`, { product: data }).then((product) => {
      if (product.errors) {
        alert(
          "We're sorry, but we couldn't update the product. Please try again."
        );
        return;
      }

      alert("Product updated successfully!", "success");
    });
  };

  const handleAccountAdd = function (e) {
    const account_id = e.target.value;
    if (!account_id) {
      return;
    }

    api
      .post(`/account_products`, {
        account_product: {
          account_id: account_id,
          product_id: id,
        },
      })
      .then((response) => {
        window.location.reload();
      });
  };

  function handleSizeChange(event, id) {
    const newSize = event.target.value;
    api.put(`/product_sizes/${id}`, { product_size: { size: newSize } });
  }

  function handlePriceChange(event, id) {
    const price = event.target.value;
    api.put(`/product_sizes/${id}`, { product_size: { price: price } });
  }

  const disableAccount = (account_product_id) => {
    if (
      window.confirm(
        "Are you sure you want to disable this account for this product?"
      )
    ) {
      api.delete(`/account_products/${account_product_id}`).then(() => {
        const updatedProduct = { ...product };
        updatedProduct.account_products =
          updatedProduct.account_products.filter(
            (account_product) => account_product.id !== account_product_id
          );
        window.location.reload();
      });
    }
  };

  const createProductSize = (size, price) => {
    api
      .post(`/product_sizes`, {
        product_size: {
          product_id: id,
          size,
          price,
        },
      })
      .then(() => {
        window.location.reload();
      });
  };

  if (!product || !accounts.length || !vendors.length) {
    return null;
  }

  return (
    <div className="container">
      <BreadCrumb
        items={[
          ["Admin", "/admin"],
          ["Products", "/admin/products"],
          product.name,
        ]}
      />

      <form onSubmit={handleProductUpdate}>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Name</label>
            <input
              required
              type="text"
              id="name"
              name="name"
              defaultValue={product.name}
            />
          </div>

          <div className="column">
            <label htmlFor="vendor_id">Vendor</label>
            <select
              required
              name="vendor_id"
              id="vendor_id"
              defaultValue={product.vendor.id}
            >
              <option value="" disabled>
                Select Vendor
              </option>
              {vendors.map((vendor) => (
                <option key={vendor.id} value={vendor.id}>
                  {vendor.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <button type="submit" className="button-primary">
              Update Product
            </button>
          </div>
        </div>
      </form>

      <div className="row">
        <div className="column">
          <label htmlFor="account_id">Product Sizes</label>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Size</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {product.product_sizes.map((product_size) => (
            <tr key={product_size.id}>
              <td>
                <input
                  type="text"
                  defaultValue={product_size.size}
                  onChange={(e) => handleSizeChange(e, product_size.id)}
                />
              </td>
              <td>
                <input
                  type="number"
                  defaultValue={product_size.price}
                  onChange={(e) => handlePriceChange(e, product_size.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button type="button" onClick={() => setShowProductSizeModal(true)}>
        Create a Product Size
      </button>

      <hr />

      <div className="row">
        <div className="column">
          <label htmlFor="account_id">Enable Accounts</label>
          <select
            required
            id="account_id"
            name="account_id"
            onChange={(e) => handleAccountAdd(e)}
          >
            <option value="">Select Account</option>
            {accounts
              .filter(
                (account) =>
                  !product.account_products.find(
                    (account_product) =>
                      account_product.account.id == account.id &&
                      account_product.enabled
                  )
              )
              .map((account) => (
                <option key={account.id} value={account.id}>
                  {account.name}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div>
        {chunkArray(
          product.account_products.filter(
            (account_product) => account_product.enabled
          ),
          4
        ).map((chunk, chunkIndex) => (
          <div className="row" key={chunkIndex}>
            {chunk.map((account_product) => (
              <div
                className="column"
                key={account_product.id}
                style={{ width: "25%" }}
              >
                <div
                  style={{
                    border: "1px solid #e1e1e1",
                    borderRadius: "4px",
                    padding: "12px",
                    margin: "6px 0",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    textAlign: "center",
                  }}
                >
                  {account_product.account.name}
                  <a
                    style={{
                      float: "right",
                      color: "#ff4d4d",
                      fontSize: "23px",
                      marginTop: -6,
                      fontWeight: "bold",
                    }}
                    onClick={disableAccount.bind(null, account_product.id)}
                    title="Disable Account"
                  >
                    &times;
                  </a>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="row">
        <button
          type="button"
          onClick={deleteProduct}
          style={{
            display: "block",
            textAlign: "center",
            fontWeight: "bold",
            margin: "60px auto 0",
            color: "white",
            backgroundColor: "#ff4d4d",
            border: "none",
          }}
        >
          Delete Product
        </button>
        <br />
        <br />
        <br />
      </div>
      <br />
      <br />
      <Modal
        isOpen={showProductSizeModal}
        onClose={() => setShowProductSizeModal(false)}
      >
        <div>
          <h2>Create a Size</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              createProductSize(e.target.size.value, e.target.price.value);
            }}
          >
            <div className="row">
              <div className="column">
                <label htmlFor="size">Size</label>
                <input type="text" id="size" name="size" required />
              </div>
              <div className="column">
                <label htmlFor="price">Price</label>
                <input type="number" id="price" name="price" required />
              </div>
            </div>
            <div className="row">
              <div className="column">
                <button type="submit" className="button-primary">
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
