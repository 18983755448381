import React, { useState, useEffect } from "react";
import { useOrder } from "../providers";

export default function ShippingAddress({ handleAttributeUpdate }) {

  const { office } = useOrder();
  const [address, setAddress] = useState(office?.address)
  
  useEffect(() => {
    setAddress(office?.address);
    handleAttributeUpdate('shipping_address', office?.address)
  }, [office])

  if (!office) return null;
  
  return (
      <div className="column">
        <label>
          Shipping Address
          <textarea
            required
            value={address}
            onChange={(e) => { setAddress(e.target.value); handleAttributeUpdate('shipping_address', e.target.value)}}
          />
        </label>
      </div>
  );
}