import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "@providers/api";
import BreadCrumb from "@shared/BreadCrumb";

export default function CreateAccount() {
  const api = useApi();
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    api.get("/vendors").then((response) => {
      setVendors(response);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const product = Object.fromEntries(formData);

    api.post(`/products`, { product }).then((product) => {
      if (product.errors) {
        alert(
          "We're sorry, but we couldn't create the product. Please try again."
        );
        return;
      }

      navigate(`/admin/products`);
    });
  };

  return (
    <div className="container">
      <BreadCrumb
        items={[
          ["Admin", "/admin"],
          ["Products", "/admin/products"],
          "Create Product",
        ]}
      />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Name</label>
            <input required type="text" name="name" id="name" />
          </div>
          <div className="column">
            <label htmlFor="vendor_id">Vendor</label>
            <select required name="vendor_id" id="vendor_id">
              <option value="" disabled>
                Select Vendor
              </option>
              {vendors.map((vendor) => (
                <option key={vendor.id} value={vendor.id}>
                  {vendor.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="column text-center">
            <button type="submit">Create Product</button>
          </div>
        </div>
      </form>
    </div>
  );
}
