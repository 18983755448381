export { formatDateTime, formatDate } from "./date";
export { formatPhoneNumber } from "./phone";
export { default as formatPrice } from "./price";
export { sumPurchaseOrder } from "./price";
export { default as once } from "./once";

export function chunkArray(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}
