import React from "react";

export default function Home() {
  return (
    <div className="container padded">
      <h1>Elite Wound Care Consultants</h1>
      <p>
        Welcome to the new Elite Wound Care Consultants portal. You can use this
        portal to place orders, view your order history, 
        and more.
      </p>
    </div>
  );
}