import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { useNavigate } from "react-router-dom";
import { useApi } from "@providers/api";
import { formatDate } from "@util";
import Loading from "@shared/Loading";
import BreadCrumb from "@shared/BreadCrumb";
import "./style.css";

const searchOptions = {
  keys: [
    "invoice_number",
    "invoice_purchase_orders.purchase_order.order_number",
    "invoice_purchase_orders.purchase_order.purchase_order_number",
  ],
  threshold: 0.3,
};

export default function Invoices() {
  const searchPlaceholder = "Search Invoice Numbers or Order Numbers";
  const api = useApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [fuse, setFuse] = useState(null);
  const [filteredBySearch, setFilteredBySearch] = useState(null);

  useEffect(() => {
    api.get("/invoices").then((response) => {
      setInvoices(response);
      setFilteredInvoices(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (invoices) {
      setFuse(new Fuse(invoices, searchOptions));
    }
  }, [invoices]);

  // Combine filters and search
  useEffect(() => {
    if (filteredBySearch) {
      setFilteredInvoices(filteredBySearch);
    } else {
      setFilteredInvoices(invoices);
    }
  }, [filteredBySearch]);

  const handleSearch = (event) => {
    const search = event.target.value.toLowerCase();
    if (!search) {
      setFilteredBySearch(null);
      return;
    }

    const filtered = fuse.search(search);
    setFilteredBySearch(filtered.map((result) => result.item));
  };

  if (loading) return <Loading />;

  if (!invoices?.length)
    return (
      <div className="container">
        <BreadCrumb items={["Invoices"]} />
        <div className="center-middle">
          <div className="text-center">
            <p>
              <strong>No invoices found.</strong>
            </p>
          </div>
        </div>
      </div>
    );

  return (
    <div className="container">
      <BreadCrumb items={["Invoices"]} />
      <div className="row">
        <div className="column">
          <input
            type="text"
            placeholder={searchPlaceholder}
            onChange={handleSearch}
          />
        </div>
      </div>
      <table className="invoices-table">
        <thead>
          <tr>
            <th>Invoice Number</th>
            <th>Status</th>
            <th>Purchase Order Numbers</th>
            <th>Order Numbers</th>
            <th>Sent At</th>
          </tr>
        </thead>
        <tbody>
          {filteredInvoices?.map((invoice) => (
            <tr
              key={invoice.id}
              className="invoices--action-row"
              onClick={() => navigate(`/invoices/${invoice.id}`)}
            >
              <td>{invoice.invoice_number}</td>
              <td>{invoice.status}</td>
              <td style={{ whiteSpace: "pre" }}>
                {invoice.invoice_purchase_orders
                  ?.map(
                    (invoice_purchase_order) =>
                      invoice_purchase_order?.purchase_order
                        ?.purchase_order_number
                  )
                  .join("\n")}
              </td>
              <td style={{ whiteSpace: "pre" }}>
                {invoice.invoice_purchase_orders
                  ?.map(
                    (invoice_purchase_order) =>
                      invoice_purchase_order?.purchase_order?.order_number
                  )
                  .join("\n")}
              </td>
              <td>{formatDate(invoice.sent_at) || "Unsent"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
