import React from "react";
import { useOrder } from "../providers";
import { useUser } from "@providers/user";

export default function SelectOrderingOffice() {
  const { setOffice } = useOrder();
  const user = useUser();

  const handleSelect = (e) => {
    const officeId = e.target.value;
    if (!officeId) {
      setOffice(null);
      return;
    }

    setOffice(user.offices.find((office) => office.id == officeId));
  };

  if (!user.offices) {
    return (
      <div>
        You are not currently associated with any offices. Please contact your
        administrator to get assigned to an office.
      </div>
    );
  }

  if (user.offices.length === 1) {
    setOffice(user.offices[0]);
    return null;
  }

  return (
    <div className="column">
      <label>
        Ordering Office
        <select onChange={handleSelect}>
          <option value="">Select an office</option>
          {user.offices.map((office, index) => (
            <option key={office.id} value={office.id}>
              {office.name}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
}
