// This component is only used by the List component to display the details of a Purchase Order.
// It is not used anywhere else in the application.
import React from "react";
import FixedPair from "@shared/FixedPair";
import MailTo from "@shared/MailTo";

import { formatDateTime, formatDate, formatPhoneNumber } from "@util";

export default function PurchaseOrderDetails({ purchase_order }) {
  return (
    <div>
      <div className="row">
        <div className="column">
          <strong>
            Purchase Order No. {purchase_order?.purchase_order_number}
          </strong>
        </div>
        <div className="column">
          <strong>Shipping</strong>
        </div>
      </div>
      <br/>

      <div className="row">
        <div className="column">
          <FixedPair
            strong
            padded
            label="Service Date"
            value={formatDateTime(purchase_order?.service_date)}
          />
          <FixedPair padded label="Doctor" value={purchase_order?.doctor} />
          <FixedPair padded label="Contact" value={(
            <>
              <div>{purchase_order?.contact_name}</div>
              <div>{formatPhoneNumber(purchase_order?.contact_phone)}</div>
              <div><MailTo email={purchase_order?.contact_email} /></div>
            </>
          )} />
        </div>
        <div className="column">
          <FixedPair padded label="Ship To" value={purchase_order?.shipping_address} />
          <FixedPair padded label="Status" value={purchase_order?.status} />
          <FixedPair
            padded
            label="Tracking No."
            value={( purchase_order?.tracking_number ) && (
                <a href={`https://www.fedex.com/fedextrack/?trknbr=${purchase_order.tracking_number}`} target="_blank">{purchase_order.tracking_number}</a>
            )} />
          <FixedPair padded label="Date Shipped" value={formatDate(purchase_order?.date_shipped)} />
        </div>
      </div>
    </div>
  );
}
