import React from 'react';
import HashLoader from "react-spinners/HashLoader";
export default function Loading() {
  return (
    <div className="container">
      <div className="center-middle">
        <HashLoader color="#1a76bc" loading={true} size={50} />
      </div>
    </div>
  )
}