import React from "react";
import "./style.css";

export default function BreadCrumb({ items }) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {items.map((item, index) => (
          <li key={index} className="breadcrumb-item">
            {index === items.length - 1 ? (
              <span>{item}</span>
            ) : (
              <a href={item[1]}>{item[0]}</a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}