import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "@providers/api";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "@shared/BreadCrumb";

export default function Vendor() {
  const api = useApi();
  const [vendor, setVendor] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/vendors/${id}`).then((vendor) => {
      // if the vendor is not found, show a 404 message
      if (vendor.status === 404) {
        navigate("/404?message=Vendor+not+found");
        return;
      }
      setVendor(vendor);
    });
  }, [id]);

  const deleteVendor = () => {
    if (window.confirm("Are you sure you want to delete this vendor?")) {
      api.delete(`/vendors/${id}`).then(() => {
        navigate("/admin/vendors");
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    api.put(`/vendors/${id}`, { vendor: data }).then((vendor) => {
      if (vendor.errors) {
        alert("We're sorry, but we couldn't update the vendor. Please try again.");
        return;
      }

      navigate(`/admin/vendors`);
    });
  }

  if (!vendor) {
    return null;
  }

  return (
    <div className="container">
      <BreadCrumb
        items={[
          ["Admin", "/admin"],
          ["Vendors", "/admin/vendors"],
          vendor.name,
        ]}
      />
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="column">
              <label htmlFor="name">Name</label>
              <input required type="text" name="name" id="name" defaultValue={vendor.name}/>
            </div>
            <div className="column">
              <label htmlFor="email">Email</label>
              <input required type="email" name="email" id="email" defaultValue={vendor.email}/>
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label htmlFor="distributor_name">Distributor Name</label>
              <input required defaultValue={vendor.distributor_name} type="text" name="distributor_name" id="distributor_name" />
            </div>
            <div className="column">
              <label htmlFor="distributor_contact">Distributor Contact</label>
              <input required defaultValue={vendor.distributor_contact} type="text" name="distributor_contact" id="distributor_contact" />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label htmlFor="distributor_number">Distributor Number</label>
              <input required defaultValue={vendor.distributor_number} type="text" name="distributor_number" id="distributor_number" />
            </div>
            <div className="column">
              <label htmlFor="sales_rep">Sales Rep</label>
              <input required defaultValue={vendor.sales_rep} type="text" name="sales_rep" id="sales_rep" />
            </div>
          </div>

          <button type="submit">Update</button>
        </form>
          
          {/* <hr />
          <a
            onClick={deleteVendor}
            style={{
              display: "block",
              textAlign: "center",
              fontWeight: "bold",
              marginTop: "60px",
              color: "red",
            }}
          >
            Delete Vendor
          </a> */}
    </div>
  );
}
