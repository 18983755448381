import React from "react";

export default function Shipped() {
  return (
    <span
      style={{
        display: "inline-block",
        width: 12,
        height: 12,
        marginRight: 10,
        borderRadius: 10,
        backgroundColor: "rgb(30,144,255)",
      }}
    ></span>
  );
}
