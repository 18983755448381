import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "@providers/api";
import BreadCrumb from "@shared/BreadCrumb";
import { Link } from "react-router-dom";

export default function Office() {
  const api = useApi();
  const [office, setOffice] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const { id } = useParams();
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    // Set the selected account ID once accounts data is available and office data is loaded
    if (accounts.length > 0 && office && office.account) {
      setSelectedAccountId(office.account.id);
      setEnabled(office.enabled);
    }
  }, [accounts, office?.account]);

  const load = () =>
    api.get(`/offices/${id}`).then((office) => {
      setOffice(office);
    });

  useEffect(() => {
    api.get("/accounts").then((accounts) => {
      setAccounts(accounts);
    });
  }, []);

  useEffect(() => {
    load();
  }, [id]);

  if (!office || !accounts) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    data.enabled = enabled;
    api.put(`/offices/${id}`, { office: data }).then((response) => {
      if (response.errors) {
        alert("Error: " + response.errors.join(", "));
        return;
      }
      alert("Office updated successfully", "green");
      window.location.reload();
    });
  };

  const disableOffice = () => {
    if (window.confirm("Are you sure you want to disable this office?")) {
      api
        .put(`/offices/${id}`, { office: { disabled: true } })
        .then((response) => {
          if (response.errors) {
            alert("Error: " + response.errors.join(", "));
            return;
          }
          alert("Office disabled successfully", "green");
          window.location.reload();
        });
    }
  };

  return (
    <div className="container">
      <BreadCrumb
        items={[
          ["Admin", "/admin"],
          ["Offices", "/admin/offices"],
          office.name,
        ]}
      />

      {office.office_users.length === 0 ? (
        <div style={{ color: "red", padding: "30px 0" }}>
          <strong>Warning:</strong> This office has no users assigned to it.
        </div>
      ) : null}

      {office.office_users.filter((office_user) =>
        office_user.user.user_roles.find(
          (user_role) => user_role.role === "doctor"
        )
      ).length === 0 ? (
        <div style={{ color: "red", padding: "30px 0" }}>
          <strong>Warning:</strong> This office has no doctors assigned to it.
        </div>
      ) : null}

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="column">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              defaultValue={office.name}
            />
          </div>
          <div className="column">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              name="address"
              id="address"
              defaultValue={office.address}
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              defaultValue={office.phone}
            />
          </div>

          <div className="column">
            <label htmlFor="account_id">Account</label>
            <select
              required
              name="account_id"
              id="account_id"
              value={selectedAccountId}
              onChange={(e) => setSelectedAccountId(e.target.value)}
            >
              <option value="">Select Account</option>
              {accounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <label htmlFor="enabled">Status</label>
          <select
            required
            name="enabled"
            id="enabled"
            value={enabled}
            onChange={(e) =>
              setEnabled(e.target.value == "true" ? true : false)
            }
          >
            <option value={true}>Enabled</option>
            <option value={false}>Disabled</option>
          </select>
        </div>

        <div className="row">
          <div className="column">
            <button type="submit">Update</button>
          </div>
        </div>
      </form>
    </div>
  );
}
