export default function formatPrice(price) {
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(Number(price));
  } catch (error) {
    return price;
  }
}


export function sumPurchaseOrder(purchase_order) {
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(
      purchase_order.purchase_order_products.reduce(
        (acc, purchase_order_product) =>
          acc + purchase_order_product.price,
        0
      )
    );
  } catch (error) {
    return "Err";
  }
}
